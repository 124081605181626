import { useCallback, useEffect, useRef, useState, useContext } from "react";
import Message from "../components/Message";
import { StatusService, TextService } from "../api";
import {
  getCharacterCount,
  getCharacterWidth,
  getRandomColor,
  getLanguage,
} from "../utils/text-utils";
import split from "lodash/split";
import { useSearchParams } from "react-router-dom";
import getRandomText from "../utils/message-util";
import client from "../api/client";

export default function MessageList() {
  const messageIdRef = useRef(0);
  const messageQueueRef = useRef([]);
  const [removeMessageCalled, setRemoveMessageCalled] = useState(false);
  const [currentTime, setCurrentTime] = useState(60);
  const [nextTime, setNextTime] = useState(currentTime - 3);
  const MAX_CHAR = 30;
  const [searchParams] = useSearchParams();
  const [isEnabled, setIsEnabled] = useState(false);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isRandom, setIsRandom] = useState(true);
  const [stage, setStage] = useState(0);
  const [repeatingTime, setRepeatingTime] = useState(0);
  const isDoneAnimationRef = useRef();
  const intervalRef = useRef();

  useEffect(() => {
    const checkIsDoneAnimated = () => {
      const isDoneAnimated = localStorage.getItem("isDoneAnimated");
      if (isDoneAnimated === "false" && !isDoneAnimationRef.current) {
        isDoneAnimationRef.current = "false";
      } else if (
        isDoneAnimated === "true" &&
        isDoneAnimationRef.current === "false"
      ) {
        isDoneAnimationRef.current = "true";
        setIsEnabled(true);
        clearInterval(intervalRef.current);
      }
    };

    checkIsDoneAnimated();

    intervalRef.current = setInterval(() => {
      checkIsDoneAnimated();
    }, 300);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  // queue for messages that are waiting to be added to the message queue
  const waitQueueRef = useRef([]);

  // maximum limit of the y coordinate
  const Y_MIN = 0.2;
  const Y_MAX = 3.0;

  // maximum left and right limit of the x coordinate
  const X_MIN = -1.7;
  const X_MAX = 1.7;

  // width and height of printable wall space
  const WALL_SPACE_WIDTH = Math.abs(X_MIN) + X_MAX;
  const WALL_SPACE_HEIGHT = Y_MAX - Y_MIN;

  const padding = 0.2;

  let timeOutRef = useRef();
  const handleUsersTimeOut = useCallback((numOfUser) => {
    if (numOfUser <= 100) {
      timeOutRef.current = 5000;
    } else if (numOfUser > 100 && numOfUser < 300) {
      timeOutRef.current = 3000;
    } else {
      timeOutRef.current = 2000;
    }
  }, []);

  const handleFetchStatus = useCallback((res) => {
    if (res.data.status === "running") {
      setCurrentTime(res.data["time-remaining"]);
    } else {
      setCurrentTime(0);
    }
  }, []);

  const handleFetchText = async (text) => {
    if (text.data.length > 0) {
      setIsRandom(false);
      handleUsersTimeOut(text.data.length);
      text.data.forEach((data) => {
        addMessage(data.text, false, false);
      });
    } else {
      setIsRandom(true);
      setTimeout(() => {
        setNextTime(currentTime);
      }, 3000);
    }
  };

  StatusService.useGetStatus(handleFetchStatus, isEnabled);
  TextService.useGetText(
    handleFetchText,
    searchParams.get("accessKey"),
    isEnabled
  );

  const { mutate: startStatus } = StatusService.useStartService();

  const checkEmptySpace = useCallback(
    (message) => {
      // ==================================================================
      // Calculate the x and y positions for the new message
      // ==================================================================
      const defaultX = X_MIN;
      const defaultY = Y_MAX;

      const messageHeight = 0.15;

      let messageWidth = 0;
      for (const char of message)
        messageWidth += getCharacterWidth(char, getLanguage(message));

      // scale down to fit the message to the wall
      let scale = 1.0;
      if (messageWidth > WALL_SPACE_WIDTH) {
        scale = WALL_SPACE_WIDTH / messageWidth;
        messageWidth = WALL_SPACE_WIDTH;
      }

      // Default spot for the new message on the wall
      let x = defaultX;
      let y = defaultY;

      let foundSpot = false;
      let i = 0;
      while (!foundSpot) {
        // wall full, add the first message to wait queue
        if (i > 500) {
          return false;
        }

        // Get left and right boundaries of the new message
        const leftBoundary = x;
        const rightBoundary = x + messageWidth;

        // Check if the message box would overlap with any existing messages
        const overlap = messageQueueRef.current.some(
          (otherMessage) =>
            // left boundary of the new message box is within the boundaries of the other message box
            leftBoundary <= otherMessage.rightBoundary + padding &&
            rightBoundary >= otherMessage.x &&
            // y is within the boundaries of the other message box
            y - messageHeight <= otherMessage.y &&
            y >= otherMessage.y - messageHeight
        );

        // const withinWallSpace = leftBoundary >= X_MIN && rightBoundary <= X_MAX && y <= Y_MAX && y - messageHeight >= Y_MIN;
        const outsideWallSpace =
          leftBoundary < X_MIN ||
          rightBoundary > X_MAX ||
          y > Y_MAX ||
          y - messageHeight < Y_MIN;

        // If there's no overlap, we've found a spot for the new message
        if (!overlap && !outsideWallSpace) {
          foundSpot = true;
        } else {
          // Otherwise, move the message box to the right and check again
          x += 0.1;
          if (leftBoundary >= X_MAX || rightBoundary >= X_MAX) {
            // If we've reached the right edge of the wall, move the box down to the next row
            x = defaultX;
            y -= messageHeight;
          }
          // We've reached the bottom row
          if (y < Y_MIN) {
            // reset to default Y position
            y = defaultY;
          }
        }
        i++;
      }

      return true;
    },
    [WALL_SPACE_WIDTH, X_MIN]
  );

  const validateMessage = (message) => {
    const textArrayWithEmoji = split(message, "");
    return textArrayWithEmoji.slice(0, MAX_CHAR).join("");
  };

  const removeMessage = useCallback(() => {
    messageQueueRef?.current?.shift();
    setMessageQueue((prevMessages) => prevMessages.slice(1));
    if (waitQueueRef.current.length > 0) {
      setRemoveMessageCalled(true);
    }
  }, [setMessageQueue]);

  const addMessage = useCallback(
    async (rawMessage, isRandom, isFromWaitQueue = false) => {
      if (!rawMessage) return;

      const message = validateMessage(rawMessage);

      if (waitQueueRef.current.length > 0 && !isFromWaitQueue) {
        if (!isRandom) {
          waitQueueRef.current = waitQueueRef.current.filter(
            (prev) => prev.isRandom === false
          );
        }
        waitQueueRef.current.push({ message, isRandom });
        return;
      }
      const language = getLanguage(message);

      if (language) {
        // ==================================================================
        // Calculate the x and y positions for the new message
        // ==================================================================
        const defaultX = X_MIN;
        const defaultY = Y_MAX;

        const messageHeight = 0.15;

        // scale down to fit the message to the wall
        let scale = 1.0;
        if (getCharacterCount(message) <= 10) {
          scale = 1.2;
        } else if (
          getCharacterCount(message) >= 20 &&
          getCharacterCount(message) <= 30
        ) {
          scale = 0.8;
        }

        let messageWidth = 0;
        for (const char of message)
          messageWidth += getCharacterWidth(char, language, scale);

        if (messageWidth > WALL_SPACE_WIDTH) {
          scale = WALL_SPACE_WIDTH / messageWidth;
          messageWidth = WALL_SPACE_WIDTH;
        }

        // Default spot for the new message on the wall
        let x = defaultX;
        let y = defaultY;

        let foundSpot = false;
        let i = 0;
        while (!foundSpot) {
          // wall full, add the first message to wait queue
          if (i > 500) {
            if (waitQueueRef.current.length === 0) {
              // Wall is full and wait queue is empty, add message to wait queue
              waitQueueRef.current.push({ message, isRandom });
              i = 0;
              return;
            } else if (isFromWaitQueue) {
              // Message is from the wait queue but doesn't fit,
              // add message back to wait queue as the first index
              waitQueueRef.current.unshift({ message, isRandom });
              i = 0;
              return;
            }
          }

          // Get left and right boundaries of the new message
          const leftBoundary = x;
          const rightBoundary = x + messageWidth;

          // Check if the message box would overlap with any existing messages
          const overlap = messageQueueRef.current.some(
            (otherMessage) =>
              // left boundary of the new message box is within the boundaries of the other message box
              leftBoundary <= otherMessage.rightBoundary + padding &&
              rightBoundary >= otherMessage.x &&
              // y is within the boundaries of the other message box
              y - messageHeight <= otherMessage.y &&
              y >= otherMessage.y - messageHeight
          );

          // const withinWallSpace = leftBoundary >= X_MIN && rightBoundary <= X_MAX && y <= Y_MAX && y - messageHeight >= Y_MIN;
          const outsideWallSpace =
            leftBoundary < X_MIN ||
            rightBoundary > X_MAX ||
            y > Y_MAX ||
            y - messageHeight < Y_MIN;

          // If there's no overlap, we've found a spot for the new message
          if (!overlap && !outsideWallSpace) {
            foundSpot = true;
          } else {
            // Otherwise, move the message box to the right and check again
            x += 0.1;
            if (leftBoundary >= X_MAX || rightBoundary >= X_MAX) {
              // If we've reached the right edge of the wall, move the box down to the next row
              x = defaultX;
              y -= messageHeight;
            }
            // We've reached the bottom row
            if (y < Y_MIN) {
              // reset to default Y position
              y = defaultY;

              // scrapped this part because it was causing the message to disappear
              // // If we've reached the bottom row, we can't find a spot for the message
              // // and we exit the loop without adding it to the message list
              // return null;
            }
          }
          i++;
        }

        const rightBoundary = x + messageWidth;

        const newMessage = {
          key: messageIdRef.current,
          message,
          language,
          color: getRandomColor(),
          scale,
          x,
          y,
          rightBoundary,
          isRandom,
        };

        // Add the new message to the message list with its calculated position

        messageQueueRef.current.push(newMessage);
        setMessageQueue((prevMessageQueue) => [
          ...prevMessageQueue,
          newMessage,
        ]);
        messageIdRef.current += 1;

        // remove Message after some time
        setTimeout(() => removeMessage(newMessage), timeOutRef.current);

        return newMessage;
      } else {
        await client.post(`/errors`, {
          error: `line 324 MessageList ${rawMessage}`,
        });
        console.log(`rawMessage ${rawMessage}`);
        return;
      }
    },
    [WALL_SPACE_WIDTH, X_MIN, removeMessage]
  );

  const handleGetRandomMessage = useCallback(async () => {
    try {
      if (
        nextTime - 3 === currentTime &&
        JSON.parse(localStorage.getItem("isDoneAnimated")) === true &&
        isRandom
      ) {
        const regex = /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        const additionRegex = /\{(\d+)\}/;
        timeOutRef.current = 5000;
        setTimeout(() => {
          let text = getRandomText(
            stage,
            repeatingTime,
            setStage,
            setRepeatingTime
          ).replace(regex, "");
          text = text.replace(additionRegex, "");
          if (text !== undefined) {
            if (
              waitQueueRef.current.filter((prev) => prev.isRandom).length < 10
            ) {
              addMessage(text, true, false);
            }
          }
        }, 200);
      }
    } catch (e) {
      if (e instanceof Error) {
        await client.post("/errors", {
          error: `${e.message} line 354 MessageList`,
        });
        console.log(e);
      }
    }
  }, [addMessage, currentTime, isRandom, nextTime, repeatingTime, stage]);

  useEffect(() => {
    handleGetRandomMessage();
  }, [handleGetRandomMessage]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("isDoneAnimated")) === true &&
      currentTime !== -1
    ) {
      setIsEnabled(true);
    }
  }, [currentTime, setIsEnabled]);

  useEffect(() => {
    if (currentTime <= 5) {
      setIsEnabled(false);
      setMessageQueue([]);
      waitQueueRef.current = [];
      messageQueueRef.current = [];
    }
  }, [currentTime, setIsEnabled]);

  useEffect(() => {
    if (removeMessageCalled) {
      let firstMessageFromWaitQueue;
      if (messageQueueRef.current.length > 0)
        firstMessageFromWaitQueue = waitQueueRef.current[0];

      let firstQueueItem;
      if (firstMessageFromWaitQueue)
        firstQueueItem = waitQueueRef.current.shift();
      if (firstQueueItem != undefined)
        addMessage(firstQueueItem.message, firstQueueItem.isRandom, true);
    }

    // Reset the state variable
    setRemoveMessageCalled(false);
  }, [addMessage, removeMessageCalled]);

  // Run once on component mount
  useEffect(() => {
    startStatus();

    // Check for empty space every 200ms to push messages from the wait queue faster
    const checkEmptySpaceInterval = setInterval(() => {
      let firstMessageFromWaitQueue;
      if (waitQueueRef.current.length > 0) {
        firstMessageFromWaitQueue = waitQueueRef.current[0];
      }

      if (
        firstMessageFromWaitQueue &&
        checkEmptySpace(firstMessageFromWaitQueue.message)
      ) {
        const firstQueueItem = waitQueueRef.current.shift();
        if (
          firstQueueItem != undefined &&
          firstQueueItem?.message &&
          firstQueueItem?.isRandom
        )
          addMessage(firstQueueItem.message, firstQueueItem.isRandom, true);
      }
    }, 200);
    // Return a cleanup function to stop the interval when the component unmounts
    return () => {
      clearInterval(checkEmptySpaceInterval);
    };
  }, [addMessage, checkEmptySpace, startStatus]);

  const renderMessage = (message) => {
    return (
      message && (
        <group key={"Group" + message.key}>
          <Message
            key={message.key}
            messageId={message.key}
            color={message.color}
            modifiedScale={message.scale}
            x={message.x}
            y={message.y}
            text={message.message}
            language={message.language}
            rightBoundary={message.rightBoundary}
            removeTimeout={timeOutRef.current}
          />
        </group>
      )
    );
  };

  return <>{messageQueue.map(renderMessage)}</>;
}
