import { extend } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import { Color } from 'three';

const CustomShaderMaterial = shaderMaterial(
    { uColor: new Color(0.2, 0.2, 0.8) },
    // Vertex Shader
    `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
    // Fragment Shader
    `
  uniform vec3 uColor;
  varying vec2 vUv;

  void main() {
    gl_FragColor = vec4(uColor, 0.0);
  }
  `
);

extend({ CustomShaderMaterial });

export default CustomShaderMaterial;
