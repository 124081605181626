import client from "../client";
import { useMutation, useQuery } from "@tanstack/react-query";

const StatusService = {
  useStartService: () =>
    useMutation({
      mutationFn: async () => {
        return await client.post(`/service/start`);
      },
      onError: async (res) => {
        await client.post(`/errors`, {
          error: `StatusService StartService line 11 ${res}`,
        });
      },
    }),
  useStopService: async () => {
    return await client.post(`/service/stop`);
  },
  restartService: async () => {
    return await client.post(`/service/restart`);
  },
  useGetStatus: (successFn, isDone) =>
    useQuery({
      queryKey: ["fetchingStatus"],
      queryFn: async () => {
        return await client.get(`/service/status`);
      },
      onError: async (res) => {
        await client.post(`/errors`, {
          error: `StatusService useGetStatus line 27 ${res}`,
        });
      },
      refetchInterval: 250,
      onSuccess: successFn,
      enabled: isDone,
    }),
};

export default StatusService;
