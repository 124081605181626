const EMOJI = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "😂",
  "🤣",
  "🥹",
  "😊",
  "😇",
  "😉",
  "😌",
  "😍",
  "🥰",
  "😘",
  "😗",
  "😙",
  "😚",
  "😋",
  "😛",
  "😝",
  "😜",
  "🤪",
  "🤓",
  "😎",
  "🤩",
  "🥳",
  "😏",
  "🥺",
  "🤭",
  "🤠",
];

const SALES_TEAM_BRAND_NAME = [
  "THE MET",
  "MoMA",
  "Whitney Museum",
  "The Guggenheim",
  "The New Museum",
  "The Frick Collection",
  "The Brooklyn Museum",
  "The International Center of Photography",
  "PACE gallery",
  "LEEUM museum of art",
  "HOAM Museum of art",
  "Gentle monster",
  "Ader Error",
  "Balenciaga",
  "GUCCI",
  "Andersson Bell",
  "thom browne",
  "Alexander McQueen",
  "Balenciaga",
  "Valentino",
  "Hermès",
  "Givenchy",
  "Bottega Veneta",
  "Versace",
  "Jimmy Choo",
  "Stella McCartney",
  "Tom Ford",
  "Ralph Lauren",
  "Calvin Klein",
  "Tommy Hilfiger",
  "H&M",
  "Zara",
  "Off-White",
  "Vetements",
  "Acne Studios",
  "Maison Margiela",
  "Patagonia",
  "UGG",
  "New Balance",
  "Hyundai Motors",
  "The Drivery",
  "Hella",
  "ZF",
  "Brose",
  "Audi",
  "BMW",
  "Mercedes-Benz",
  "Volkswagen",
  "Porsche",
  "Continental",
  "Tesla",
  "Jeep",
  "Land Rover",
  "Jaguar",
  "Volvo",
  "Ferrari",
  "Lamborghini",
  "Poltrona Frau",
  "West Elm",
  "CB2",
  "Room & Board",
  "RH Modern",
  "Ligne Roset",
  "FLOS",
  "Blu Dot",
  "Muuto",
  "Kartell",
  "HAY",
  "Herman Miller",
  "Artemide",
  "Knoll",
  "Vitra",
  "Moooi",
  "Tom Dixon",
  "USM",
  "APPLE",
  "Samsung",
  "LG",
  "Asus",
  "Panasonic",
  "Philips",
  "Fujitsu",
  "Vivo",
  "Harman Kardon",
  "Bose",
  "Bang & Olufsen",
  "Nintendo",
  "GoPro",
  "Leica",
];

const STRATEGY_TEAM_INVESTOR_ENG = [
  "Michael Bloomberg",
  "Stephen Schwarzman",
  "Brendan Wallace",
  "Chris Sacca",
  "David Tisch",
  "Esther Dyson",
  "Farzad Nazem",
  "Joshua Schachter",
  "Paul Graham",
  "Peter Kellner",
  "Tom Fallows",
  "Tom Peterson",
  "Zachary Aarons",
  "Roger Ehrenberg",
  "Aamer Abdullah",
  "David S. Rose",
  "John Frankel",
  "Nasir Jones",
  "Adam Rothenberg",
  "Charlie O'Donnell",
  "Fred Wilson",
  "Jeffrey Finkle",
  "Jalak Jobanputra",
  "Jeff Pulver",
  "Jerry Neumann",
  "Brian Cohen",
  "Amol Sarva",
  "Dave Lerner",
  "Alicia Syrett",
  "Charles Smith",
  "Evan Nisselson",
  "Robert Chang",
  "Peter Scoderling",
  "Gianfranco Filice",
  "Anthony Saleh",
  "David Mandel",
  "Gil Elbaz",
  "Techstars",
  "500 Global",
  "SOSV",
  "New Enterprise Associates",
  "Accel",
  "Sequoia Capital",
  "Plug and Play",
  "Intel Capital",
  "Andreessen Horowitz",
  "SVA",
  "Kleiner Perkins",
  "Bessemer Venture Partners",
  "Lightspeed Venture Partners",
  "FJ Labs",
  "General Catalyst",
  "Tiger Global Management",
  "Advantage Capital",
  "Index Ventures",
  "Khosla Ventures",
  "Google Ventures",
  "Insight Partners",
  "Goldman Sachs",
  "Norwest Venture Partners",
  "GGV Capital",
  "First Round Capital",
  "Greylock",
  "Battery Ventures",
  "Founders Fund",
  "Venrock",
  "Greycroft",
  "Canaan Partners",
  "Menlo Ventures",
  "Redpoint",
  "Foundation Capital",
  "Goodwater Capital",
  "Polaris Partners",
  "Gaingels",
  "Benchmark",
  "True Ventures",
  "CRV",
  "Matrix Partners",
  "Mayfield Fund",
  "Lerer Hippeau",
  "DCM Ventures",
  "BoxGroup",
  "Bain Capital Ventures",
  "RRE Ventures",
  "Felicis",
  "Atlas Venture",
  "U.S. Venture Partners",
  "Spark Capital",
  "Initialized Capital",
  "Highland Capital Partners",
  "Madrona",
  "DCVC",
  "Trinity Ventures",
  "Crosslink Capital",
  "LAUNCH",
  "Headline",
  "Lux Capital",
  "Social Starts",
  "Soma Capital",
  "Summit Partners",
  "Social Capital",
  "InterWest Partners",
  "North Bridge Venture Partners & Growth Equity",
  "Shasta Ventures",
  "Union Square Ventures",
  "8VC",
  "Flybridge",
  "Uncork Capital",
  "Oak Investment Partners",
  "Upfront Ventures",
  "Sierra Ventures",
  "Newchip",
  "Precursor Ventures",
  "GSR Ventures",
  "Western Technology Investment",
  "StartUp Health",
  "Austin Ventures",
  "Dreamit Ventures",
  "IVP",
  "Floodgate",
  "Liquid 2 Ventures",
  "Comcast Ventures",
  "NFX",
  "Correlation Ventures",
  "Draper Associates",
  "ff Venture Capital",
  "Foundry Group",
  "Morgenthaler Ventures",
  "Forum Ventures",
  "FirstMark",
  "(ERA) Entrepreneurs Roundtable Accelerator",
  "Sigma Partners",
  "Accomplice",
  "AltaIR Capital",
  "Nexus Venture Partners",
  "Sapphire Ventures",
  "Techstars Ventures",
  "Village Capital",
  "Quiet Capital",
  "Altos Ventures",
  "Meritech Capital Partners",
  "Thrive Capital",
  "US Department of Commerce, Economic Development Administation",
  "Keiretsu Forum",
  "Sutter Hill Ventures",
  "BlueRun Ventures",
  "Ignition Partners",
  "Innova Memphis",
  "Pareto Holdings",
  "Endeavor Catalyst",
  "Village Global",
  "Mohr Davidow Ventures",
  "Digital Currency Group",
  "Scale Venture Partners",
  "QED Investors",
  "Maveron",
  "Boost VC",
  "August Capital",
  "Sofinnova Investments",
  "Streamlined Ventures",
  "Wamda Capital",
  "BECO Capital",
  "MEVP (Middle East Venture Partners)",
  "500 Startups",
  "Raed Ventures",
  "Jabbar Internet Group",
  "STV (Saudi Technology Ventures)",
  "Algebra Ventures",
  "Flat6Labs",
  "Arzan VC",
  "DASH Ventures",
  "Silicon Badia",
  "Crescent Enterprises Ventures",
  "Shorooq Partners",
  "Berytech Fund",
  "iMENA Group",
  "Oman Technology Fund",
  "Faith Capital",
  "Global Ventures",
  "Middle East Venture Fund",
  "VentureSouq",
  "Mubadala Ventures",
  "Fintech Galaxy",
  "Foursan Group",
  "Vision Ventures",
  "Elm VC",
  "TechInvest",
  "Mad’a Investment Company",
  "Middle East Venture Partners",
  "Modus Capital",
  "Optimiza Partners",
  "Seedra Ventures",
  "Zone Ventures",
  "Watar Partners",
  "Emkan Capital",
  "Saned Partners",
  "Sawari Ventures",
  "Eonite Perception",
  "TwinBridge Capital Partners",
  "Sawari Ventures",
  "Innovative Startups Accelerator",
  "AFKARMENA Holding Company",
  "EquiTrust",
  "Vision Ventures",
  "Qatar Science & Technology Park",
  "Tamer Group",
  "TMT Investments",
  "Abu Dhabi Investment Office",
  "Dubai Silicon Oasis Authority",
  "NAMA Ventures",
  "Hillhouse Capital Group",
  "IDG Capital",
  "Qiming Venture Partners",
  "GGV Capital",
  "Northern Light Venture Capital",
  "Matrix Partners China",
  "Legend Capital",
  "Trustbridge Partners",
  "ZhenFund",
  "Morningside Venture Capital",
  "5Y Capital",
  "CDH Investments",
  "Innovation Works",
  "Shunwei Capital",
  "Gaorong Capital",
  "CBC Capital",
  "Lightspeed China Partners",
  "Source Code Capital",
  "MSA Capital",
  "Future Capital",
  "01VC",
  "K2VC",
  "QF Capital",
  "SAIF Partners",
  "Yunqi Partners",
  "Share Capital",
  "Gobi Partners",
  "Jeneration Capital Management",
  "Plum Ventures",
  "ClearVue Partners",
  "Delta Capital",
  "China Creation Ventures",
  "FutureCap",
  "CDIB Capital",
  "Zhenge Fund",
  "Glory Ventures",
  "C Ventures",
  "Fortune Capital",
  "Chenshan Capital",
  "Tiantu Capital",
  "Oriental Fortune Capital",
  "SourceVC",
  "Vertex Ventures China",
  "Northern Light Venture Capital",
  "ChinaEquity Group",
  "Ivy Capital",
  "FreesFund",
  "WinHi",
  "BAI",
  "Cowin Capital",
  "Atomico",
  "Index Ventures",
  "Balderton Capital",
  "Northzone",
  "EQT Ventures",
  "Accel",
  "Earlybird Venture Capital",
  "HV Holtzbrinck Ventures",
  "Partech Partners",
  "Balderton Capital",
  "Idinvest Partners",
  "DN Capital",
  "Target Global",
  "Lakestar",
  "83North",
  "Point Nine Capital",
  "Felix Capital",
  "Draper Esprit",
  "Seraphim Capital",
  "Speedinvest",
  "Creandum",
  "Mosaic Ventures",
  "Kindred Capital",
  "MMC Ventures",
  "Cherry Ventures",
  "Notion Capital",
  "Project A Ventures",
  "Frog Capital",
  "Mangrove Capital Partners",
  "A/O PropTech",
  "Connect Ventures",
  "Hoxton Ventures",
  "White Star Capital",
  "Nauta Capital",
  "Practica Capital",
  "Astanor Ventures",
  "Quadrille Capital",
  "Isomer Capital",
  "Capnamic Ventures",
  "Point72 Ventures",
  "Sunstone Technology Ventures",
  "Cavalry Ventures",
  "Serendipity Capital",
  "Omnes Capital",
  "C4 Ventures",
  "Inventure",
  "Expon Capital",
  "Seedcamp",
  "Techstars",
  "Next47",
];

const STRATEGY_TEAM_INVESTOR_KOR = [
  "민준홍",
  "Okada Sayaka",
  "Masataka Ihara",
  "박현민",
  "김제현",
  "이효진",
  "정지훈",
  "김세직",
  "이동엽",
  "최은관",
  "송시야",
  "아이엑스파트너스",
  "이그나이트엑셀",
  "박영호",
  "율리시스코리아",
  "이현",
  "그리트 투자조합",
  "문지철",
  "남형걸",
  "최지웅",
  "민병훈",
  "최승국",
  "김민선",
  "박장우",
  "송욱",
  "신기준",
  "조성준",
  "최수지",
  "허정윤",
  "카카오벤처스",
  "카이트창업가재단",
  "본엔젤스벤처파트너스",
  "퓨처플레이",
  "쿨리지코너 인베스트먼트",
  "블루포인트파트너스",
  "현대자동차",
  "인포뱅크",
  "카이스트 청년창업투자지주",
  "스프링캠프",
  "포스텍홀딩스",
  "충북창조경제",
  "혁신센터",
  "선보엔젤파트너스",
  "휴젤",
  "시너지아이비투자",
  "마그나",
  "인베스트먼트",
  "빅뱅엔젤스",
  "아이빌트",
  "에버그린 투자파트너스",
  "코맥스 벤처러스",
  "킹슬리벤처스",
  "KB인베스트먼트",
  "고려대학교",
  "기술지주",
  "매쉬업엔젤스",
  "서울대학교기술지주",
  "한양대학교기술지주",
  "에이치지 이니셔티브",
  "인라이트벤처스",
  "메디톡스벤처투자",
  "김기사랩",
  "미래과학기술지주",
  "빅베이슨캐피탈",
  "씨엔티테크",
  "엔피프틴파트너스",
  "플래티넘기술투자",
  "대덕벤처파트너스",
  "더존홀딩스",
  "데일리",
  "파트너스",
  "벤처스퀘어",
  "부산지역대학",
  "연합기술지주",
  "슈미트",
  "스파크랩",
  "캡스톤파트너스",
  "프라이머",
  "플랜에이치벤처스",
  "한국과학기술지주",
  "대경지역대학",
  "공동기술지주",
  "더벤처스",
  "디티앤",
  "인베스트먼트",
  "스마일게이트",
  "인베스트먼트",
  "아주아이비투자",
  "연세대학교",
  "기술지주",
  "이노폴리스",
  "파트너스",
  "포스코기술투자",
  "JB벤처스",
  "끌림벤처스",
  "뉴패러다임",
  "인베스트먼트",
  "더인벤션랩",
  "소풍벤처스",
  "씨앤벤처파트너스",
  "액트너랩",
  "애트리홀딩스",
  "엔슬파트너스",
  "울산창조경제",
  "혁신센터",
  "패스트벤처스",
  "BSK인베스트먼트",
  "에이티넘인베스트",
  "해시드",
  "디캠프",
  "아주 IB",
  "신한퓨처스",
  "기술보증기금",
  "미래에셋벤처투자",
  "F&F 파트너스",
  "코앤트리인베스트먼트",
  "HB 인베스트먼트",
  "어니스트벤처스",
  "TS인베스트먼트",
  "넥스트드림엔젤스",
  "산업은행",
  "유안타인베스트먼트",
  "하나벤처스",
  "ES인베스터",
  "소프트뱅크벤처스 아시아",
  "인사이트에퀴티파트너스",
  "넥스트랜스",
  "베이스 인베스트먼트",
  "더피치",
  "알토스벤처스",
  "패스파인더에이치",
  "DSC인베스트먼트",
  "신용보증기금",
  "500스타트업",
  "프리미어파트너스",
  "네이버 D2SF",
  "SV인베스트먼트",
  "스트롱벤처스",
  "VNTG",
  "위벤처스",
  "어센도벤처스",
  "블리스바인벤처스",
  "비에이파트너스",
  "뮤렉스파트너스",
  "윤민창의투자재단",
  "티비티 파트너스",
  "나눔엔젤스",
  "스틱벤처스",
  "제피러스랩",
  "더웰스인베스트먼트",
  "넥스트드림엔젤클럽",
  "에이치지이니셔티브",
  "클래스벤처",
  "특허법인도담",
  "인터베스트",
  "카카오인베스트먼트",
  "유니온투자파트너스",
  "엑센트리벤처스",
  "스톤브릿지벤처스",
  "LB인베스트먼트",
  "CKD창업투자",
  "KTB네트워크/다올인베스트먼트",
  "넥스트지인베스트먼트",
  "제이에이치제이홀딩스",
  "삼성벤처투자",
  "L&S벤처캐피탈",
  "요즈마그룹코리아",
  "비티씨인베스트먼트",
  "디에스자산운용",
  "옐로우독",
  "원익투자파트너스",
  "이에스인베스터",
  "프라이머사제파트너스",
  "액세스벤처스",
  "블랙마운틴벤처스",
  "트랜스링크인베스트먼트",
  "D3쥬빌리파트너스",
  "스파크랩벤처스",
  "어썸벤처스",
  "포항공과대학교 기술지주",
  "두나무앤파트너스",
  "하이트진로",
  "경기창조경제혁신센터",
  "마크앤컴퍼니",
  "시리즈벤처스",
  "탭엔젤파트너스",
  "GS리테일",
  "와이앤아처",
  "경남창조경제혁신센터",
  "국민대학교 기술지주",
  "리벤처스",
  "엠와이 소셜컴퍼니",
  "크립톤",
  "킹고스프링",
  "한국투자파트너스",
  "라이트하우스",
  "이장원",
  "오티움캐피탈",
  "컴투스",
  "메타인베스트먼트",
];

const STRATEGY_TEAM_RANDOM_TEXT_ENG = [
  "Let's make dreams come true!",
  "Let's do it!",
  "Let's make it!",
  "Let's go to future!",
];

const STRATEGY_TEAM_RANDOM_TEXT_KOR = [
  "덕분에 여기까지 왔어!",
  "정말 고마워!!",
  "사랑해!!",
  "같이 가보자!",
  "함께하자!",
  "같이 세계로 가자!",
  "같이 가자!",
];

const MARKETING_TEAM_CELEBRITY_NAME = [
  "Damien Hirst",
  "Marina Abramović",
  "Jeff Koons",
  "Yoko Ono",
  "Ai Weiwei",
  "Olafur Eliasson",
  "Tracey Emin",
  "Kaws",
  "Takashi Murakami",
  "Anish Kapoor",
  "David Hockney",
  "Cindy Sherman",
  "Yayoi Kusama",
  "Damien Ortega",
  "Olaf Breuning",
  "Kara Walker",
  "Maurizio Cattelan",
  "Anselm Kiefer",
  "Shirin Neshat",
  "Banksy",
  "Shepard Fairey",
  "Ariana Grande",
  "Beyoncé",
  "Taylor Swift",
  "Selena Gomez",
  "Justin Bieber",
  "Rihanna",
  "Katy Perry",
  "Dua Lipa",
  "Billie Eilish",
  "Cardi B",
  "Ed Sheeran",
  "Drake",
  "Nicki Minaj",
  "Bruno Mars",
  "Shawn Mendes",
  "The Weeknd",
  "Lady Gaga",
  "Adele",
  "Harry Styles",
  "Lizzo",
  "Coldplay",
  "SZA",
  "Khalid",
  "Miley Cyrus",
  "Post Malone",
  "Halsey",
  "Camila Cabello",
  "John Legend",
  "Lana Del Rey",
  "Sam Smith",
  "Justin Timberlake",
  "BTS",
  "EXO",
  "BLACKPINK",
  "NCT",
  "Red Velvet",
  "Twice",
  "GOT7",
  "Seventeen",
  "IU",
  "DJ Khaled",
  "Future",
  "Jack Harlow",
  "Kendrick Lamar",
  "Pusha T",
  "Doja Cat",
  "Zara Larsson",
  "Ellie Goulding",
  "Kehlani",
  "Charlie Puth",
  "Lewis Capaldi",
  "Imagine Dragons",
  "Sia",
  "Madonna",
  "Christina Aguilera",
  "IVE",
  "Le SSERAFIM",
  "JOJI",
  "88rising",
  "청하",
  "aespa",
  "New Jeans",
  "Sigrid",
  "The 1975",
  "Beabadoobee",
  "Steve Lacy",
  "Anitta",
  "Omar Apollo",
  "DOMi & JD Beck",
  "Samara Joy",
  "Muni Long",
  "Latto",
  "Måneskin",
  "Tobe Nwigwe",
  "Molly Tuttle",
  "Wet Leg",
  "Chris Brown",
  "Lucky Daye",
  "Mary J. Blige",
  "Robert Glasper",
  "PJ Morton",
  "Cory Henry",
  "Moonchild",
  "Tank and the Bangas",
  "Lisandro Alonso",
  "Matteo Garrone",
  "Valérie Donzelli",
  "Catherine Breillat",
  "Robin Campillo",
  "Bertrand Bonello",
  "Alice Rohrwacher",
  "Abderrahmane Sissako",
  "Catherine Corsini",
  "Todd Haynes",
  "Michel Franco",
  "Kore-eda Hirokazu",
  "Ken Loach",
  "Kaouther Ben Hania",
  "Dorota Kobiela", 
  "Hugh Welchman",
  "Yorgos Lanthimos",
  "Sean Baker",
  "Jonathan Glazer",
  "누누씨",
  "송민호",
  "탑",
  "레브벨벳 슬기",
  "김유정",
  "ITZY 유나",
  "BTS 정국",
  "정려원",
  "유라",
  "하연수",
  "나얼",
  "구혜선",
  "솔비",
  "이혜영",
  "하정우",
  "박기웅",
  "하지원",
  "구준엽",
  "김규리",
  "조승우",
  "홍광호",
  "박효신",
  "김준수",
  "박은태",
  "전동석",
  "박강현",
  "정성화",
  "규현",
  "강홍석",
  "카이",
  "최재림",
  "류정한",
  "민영기",
  "민우혁",
  "손준호",
  "신성록",
  "양준모",
  "엄기준",
  "Ben Platt",
  "Jojo",
  "Josh Groban",
  "Annaleigh Ashford",
  "Gaten Matarazzo",
  "Jessica Chastain",
  "Arian Moayed",
  "Sean Hayes",
  "Laurie Metcalf",
  "Tatiana Maslany",
  "Paul Sparks",
  "Neil Patrick Harris",
  "Jodie Comer",
  "Laura Linney",
  "Jessica Hecht",
  "Oscar Isaac",
  "Rachel Brosnahan",
  "D’Arcy Carden",
  "Katie Finneran",
  "Scott Foley",
  "Chris Sullivan",
  "Anthony Rapp",
  "Michael B. Jordan",
  "Drew Barrymore",
  "Ali Wong",
  "Austin Butler",
  "Aubrey Plaza",
  "Rian Johnson",
  "Salma Hayek Pinault",
  "Zoe Saldana",
  "Judy Blume",
  "Colin Farrell",
  "Lea Michele",
  "Simone Leigh",
  "Wolfgang Tillmans",
  "Suzan-Lori Parks",
  "Neil Gaiman",
  "Shervin Hajipour",
  "El Anatsui",
  "Colleen Hoover",
  "Jennifer Coolidge",
  "Ke Huy Quan",
  "Shah Rukh Khan",
  "Pedro Pascal",
  "Brittney Griner",
  "King Charles",
  "Salman Rushdie",
  "Peng Lifa",
  "Shannon Watts",
  "Haluk Levent",
  "Imara Jones",
  "Yvon Chouinard",
  "Mikaela Shiffrin",
  "Bella Hadid",
  "Sam Altman",
  "Thom Browne",
  "S.S. Rajamouli",
  "Matthew Barney",
  "Jenny Holzer",
  "Cai Guo-Qiang",
  "Mickalene Thomas",
  "loish",
  "Henn Kim",
  "집시",
  "peterdraws",
  "James Lewis",
  "Sveta Shubina",
  "노상호",
  "류현진",
  "이찬원",
  "이승기",
  "임영웅",
  "윤여정",
  "유재석",
  "Njideka Akunyili Crosby",
  "David Shrigley",
  "James Turrell",
  "Sterling Ruby",
  "Camille Henrot",
  "NMIXX",
  "Jorja Smith",
  "Willow Smith",
  "Avril Lavigne",
  "장민호",
  "아이유",
  "박서준",
  "장윤정",
  "안정환",
  "김연경",
  "정동원",
  "트와이스",
  "이정재",
  "Conor McGregor",
  "Lionel Messi",
  "Cristiano Ronaldo",
  "Dak Prescott",
  "LeBron James",
  "Neymar",
  "Roger Federer",
  "Lewis Hamilton",
  "Kevin Durant",
  "Tom Brady",
  "Stephen Curry",
  "Canelo Alvarez",
  "Giannis Antetokounmpo",
  "Russell Westbrook",
  "James Harden",
  "Matthew Stafford",
  "Aaron Rodgers",
  "Tiger Woods",
  "Josh Allen",
  "Tyson Fury",
  "Naomi Osaka",
  "Damian Lillard",
  "Deshaun Watson",
  "Klay Thompson",
  "Mike Trout",
  "Patrick Mahomes",
  "Kirk Cousins",
  "Jimmy Butler",
  "Max Verstappen",
  "Paul George",
  "Anthony Davis",
  "Kawhi Leonard",
  "Phil Mickelson",
  "Serena Williams",
  "Mohamed Salah",
  "John Wall",
  "Kylian Mbappé",
  "Rory McIlroy",
  "Marshon Lattimore",
  "Devin Booker",
  "Joel Embiid",
  "Amari Cooper",
  "Chris Paul",
  "Ryan Ramczyk",
  "Stefon Diggs",
  "Jake Paul",
  "Kemba Walker",
  "Russell Wilson",
  "Bradley Beal",
  "T.J. Watt",
  "이지아",
  "이민호",
  "김수현",
  "수지",
  "김소연",
  "박은석",
  "영탁",
  "강다니엘",
  "전미도",
  "한소희",
  "송혜교",
  "손예진",
  "송가인",
  "비",
  "박신혜",
  "Theaster Gates",
  "Ai Weiwei",
  "Katharina Grosse",
  "Yinka Shonibare",
  "Kylie Jenner",
  "Dwayne Johnson",
  "Kim Kardashian",
  "Khloé Kardashian",
  "Kendall Jenner",
  "이승윤",
  "이무진",
  "아이린",
  "안효섭",
  "김혜수",
  "김희재",
  "박보검",
  "Virat Kohli",
  "Jennifer Lopez",
  "Zendaya",
  "grimes",
  "the.art.room",
  "arts_help",
  "violet111dy",
  "krotchy",
  "Katya Clover",
  "ARSHAM STUDIO",
  "spynyc",
  "retroscifiart",
  "newyork_world",
  "썸머썸머Summer in New York",
  "Anacaren Gutiérrez",
  "Sophie N Gunnol",
  "Cesar Santos",
  "fomofeed",
  "Mary Lynn Buchanan",
  "Dries Ketels",
  "Mutua Matheka",
  "ArtLetterFromNYC",
  "Christopher Hemsworth",
  "Scarlett Johansson",
  "Chris Pratt",
  "Paul Rudd",
  "Benedict Cumberbatch",
  "Tom Holland",
  "Evangeline Lilly",
  "Brie Larson",
  "Simu Liu",
  "Gemma Chan",
  "Teyonah Parris",
  "Mahershala Ali",
  "Ryan Reynolds",
  "Anthony Mackie",
  "Mark Ruffalo",
  "Jeremy Renner",
  "Angourie Rice",
  "J. B. Smoove",
  "Dave Bautista",
  "Jon Favreau",
  "Natalie Portman",
  "Angela Bassett",
  "William Hurt",
  "Robert Downey Jr.",
  "Chris Evans",
  "Stan Lee",
  "Chris Hemsworth",
  "Samuel L. Jackson",
  "Cobie Smulders",
  "Gwyneth Paltrow",
  "Tom Hiddleston",
  "Paul Bettany",
  "Stellan Skarsgård",
  "Jenny Agutter",
  "Ashley Johnson",
  "Elizabeth Olsen",
  "Fernanda Toker",
  "Powers Boothe",
  "Jesse Garcia",
  "Chadwick Boseman",
  "Kerry Condon",
  "Claudia Kim",
  "Don Cheadle",
  "Gal Gadot",
  "LISA",
  "Vin Diesel",
  "Daniel Radcliffe",
  "Emma Watson",
  "Rupert Grint",
  "Tom Felton",
  "Richard Harris",
  "Matthew Lewis",
  "Harry Melling",
  "Bonnie Wright",
  "John Cleese",
  "Maggie Smith",
  "Fiona Shaw",
  "Ian Hart",
  "Alfred Enoch",
  "John Hurt",
  "Richard Griffiths",
  "Oliver Phelps",
  "Julie Walters",
  "Zoë Wanamaker",
  "손연재",
  "김연아",
  "추신수",
  "박지성",
  "장미란",
  "박태환",
  "이승엽",
  "이대호",
  "박주영",
  "양학선",
  "황의조",
  "조현우",
  "김은정",
  "박인비",
  "박상영",
  "이강인",
  "김민재",
  "김제덕",
  "안산",
  "기성용",
  "박찬호",
  "최경주",
  "Blake Griffin",
  "Bryce Harper",
  "Mashrafe Bin Mortaza",
  "Rob Gronkpwski",
  "Robert Lewandowski",
  "Gianluigi Buffon",
  "Shikhar Dhawan",
  "Sania Mirza",
  "Mushfiqur Rahim",
  "Jerome Boateng",
  "Shakib Ai Hasan",
  "Carson Wentz",
  "Javier Hernandez",
  "Dwight Howard",
  "Yi Jianlian",
  "Manny Pacquiao",
  "Isco",
  "Gonzalo Higuain",
  "Cesc Fabregas",
  "Gennady Golovkin",
  "Andy Murray",
  "Harbhajan Singh",
  "Toni Kroos",
  "Alexis Sanchez",
  "Drew Brees",
  "Stan Wawrinka",
  "Valentino Rossi",
  "Gerard pique",
  "Carmelo Anthony",
  "Yuzuru Hanyu",
  "Jan Kaláb",
  "Novo",
  "Sally Kindberg",
  "임민정 Minjung Lim",
  "채지민 Jimin Chae",
  "Anna Han",
  "정수영 Sooyoung Chung",
  "Mariart",
  "Jean-Michel Otoniel",
  "Juan Díaz-Faes",
  "Boa Mistura",
  "Okuda San Miguel",
  "Joi Murugavell",
  "Ana Barriga",
  "Iván Forcadell",
  "Edgar Plans",
  "Javier Calleja",
  "Jordi Pintó",
  "Jaime Urdiales",
  "Tony Ann",
  "José Castiella",
  "Kike Garcinuño",
  "Maíllo",
  "Mister piro",
  "Moisés Yagües",
  "Rafael Macarrón",
  "Imon Boy",
  "TKM XOXO",
  "D*Face",
  "Juan Manuel Romero",
  "Sol Felpeto",
  "Alexander Zuleta",
  "Shara Hughes",
  "LoieHollowell",
  "Avery Singer",
  "Lucy Bull",
  "이우환",
  "김환기",
  "이건용",
  "RM",
  "지드래곤",
  "HARYANTO ADIKOESOEMO",
  "LAURA ARRILLAGA-ANDREESSEN AND MARC ANDREESSEN",
  "HÉLÈNE AND BERNARD ARNAULT",
  "LAURA AND JOHN ARNOLD",
  "LAURENT ASSCHER",
  "PEDRO BARBOSA",
  "MARIA ARENA BELL AND WILLIAM BELL JR.",
  "NICOLAS BERGGRUEN",
  "Liu Xiaodong",
  "Cecily Brown",
  "Liu Wei",
  "Miquel Barcelo",
  "Günther Förg",
  "Luo Zhongli",
  "Sean Scully",
  "Zhou Chunya",
  "George Condo",
  "John Currin",
  "Zhang Xiaogang",
  "Thomas Schütte",
  "Mark Bradford",
  "Albert Oehlen",
  "Adrian Ghenie",
  "Zeng Fanzhi",
  "Keith Haring",
  "Yoshitomo Nara",
  "Richard Prince",
  "Mark Grotjahn",
  "Rudolf Stingel",
  "Christopher Wool",
  "Peter Doig",
  "Jean-Michel Basquiat",
  "Hito Steyerl",
  "Luc Tuymans",
  "Ana Mendieta",
  "Edward Ruscha",
  "Nan Goldin",
  "Kehinde Wiley",
  "Robert Mapplethorpe",
  "Louise Bourgeois",
  "Kerry James Marshall",
  "Judy Chicago",
  "Lucian Freud",
  "ERNESTO BERTARELLI",
  "JEFF BEZOS",
  "DEBRA AND LEON BLACK",
  "ANITA BLANCHARD AND MARTIN NESBITT",
  "LEN BLAVATNIK",
  "BARBARA BLUHM-KAUL AND DON KAUL",
  "DAVID BOOTH",
  "SUZANNE DEAL BOOTH",
  "KAREN AND CHRISTIAN BOROS",
  "BOTÍN FAMILY",
  "IRMA AND NORMAN BRAMAN",
  "UDO BRANDHORST",
  "PETER M. BRANT",
  "ESTRELLITA AND DANIEL BRODSKY",
  "SHANNA AND JON BROOKS",
  "JOOP VAN CALDENBORGH",
  "EDOUARD CARMIGNAC",
  "TROY CARTER",
  "TRUDY AND PAUL CEJAS",
  "MIGUEL CHANG",
  "PIERRE CHEN",
  "ADRIAN CHENG",
  "EDMUND CHENG",
  "CECILIA AND CHUNG-KIU “C.K.” CHEUNG",
  "HALIT CINGILLIOGLU AND KEMAL HAS CINGILLIOGLU",
  "PATRICIA PHELPS DE CISNEROS AND GUSTAVO A. CISNEROS",
  "ALEXANDRA AND STEVEN A. COHEN",
  "J. PATRICK COLLINS",
  "ISABEL AND AGUSTÍN COPPEL",
  "EDUARDO F. COSTANTINI",
  "ROSA AND CARLOS DE LA CRUZ",
  "BASEL DALLOUL",
  "KASSEEM “SWIZZ BEATZ” DEAN AND ALICIA KEYS",
  "BETH RUDIN DEWOODY",
  "PATRICK DRAHI",
  "YAN DU",
  "DEIRDRE AND JAMES DYSON",
  "LONTI EBERS",
  "GEORGE ECONOMOU",
  "CARL GUSTAF EHRNROOTH",
  "EISENBERG FAMILY",
  "NICOLA ERNI",
  "YAEL AND GARY FEGEL",
  "SUSAN AND LEONARD FEINSTEIN",
  "CHARLOTTE FENG FORD",
  "FRANK J. FERTITTA III AND LORENZO FERTITTA",
  "LARRY FINK",
  "FISHER FAMILY",
  "AARON I. FLEISCHMAN",
  "MICHAEL C. FORMAN AND JENNIFER RICE",
  "CECILIE FREDRIKSEN AND KATHRINE FREDRIKSEN",
  "AMANDA AND GLENN R. FUHRMAN",
  "BARBARA AND MICHAEL GAMSON",
  "DENISE AND GARY GARDNER",
  "DAVID GEFFEN",
  "YASSMIN AND SASAN GHANDEHARI",
  "DANNY GOLDBERG",
  "BERNARDO GÓMEZ MARTÍNEZ",
  "LAURENCE GRAFF",
  "KENNETH C. GRIFFIN",
  "JEFFREY GUNDLACH",
  "CHRISTINE AND ANDREW HALL",
  "PRINCE HANS-ADAM II VON UND ZU LIECHTENSTEIN",
  "HE JIANFENG",
  "JANINE AND J. TOMILSON HILL",
  "MARGUERITE HOFFMAN",
  "MAJA HOFFMANN",
  "JIMMY IOVINE AND LIBERTY ROSS",
  "DAKIS JOANNOU",
  "PAMELA J. JOYNER AND ALFRED J. GIUFFRIDA",
  "NASSER DAVID KHALILI",
  "WOONG-KI KIM",
  "JILL AND PETER KRAUS",
  "MARIE-JOSÉE AND HENRY R. KRAVIS",
  "GRAŻYNA KULCZYK",
  "KWEE BROTHERS",
  "PIERRE LAGRANGE",
  "BARRY LAM",
  "BARBARA AND JON LANDAU",
  "STEVEN LATNER AND MICHAEL LATNER",
  "JOSEPH LAU",
  "THOMAS LAU",
  "JO CAROLE AND RONALD S. LAUDER",
  "LEONARD A. LAUDER",
  "LIZ AND ERIC LEFKOFSKY",
  "LI LIN",
  "ALEC LITOWITZ AND JENNIFER LEISCHNER LITOWITZ",
  "MARGARET MUNZER LOEB AND DANIEL S. LOEB",
  "EUGENIO LÓPEZ ALONSO",
  "GEORGE LUCAS AND MELLODY HOBSON",
  "BERNARD LUMPKIN AND CARMINE BOCCUZZI",
  "VICTOR MA",
  "MARTIN Z. MARGULIES",
  "CHEECH MARIN",
  "JEFF MARKLEY",
  "DAVID MARTINEZ",
  "SUSAN AND LARRY MARX",
  "DIMITRI MAVROMMATIS",
  "RAYMOND J. MCGUIRE AND CRYSTAL MCCRARY",
  "JOHN S. MIDDLETON",
  "RODNEY MILLER",
  "JULIE AND EDWARD J. MINSKOFF",
  "JARL AND PAMELA MOHN",
  "KELLY AND SCOTT MUELLER",
  "KIRAN AND SHIV NADAR",
  "NIARCHOS FAMILY",
  "GENNY AND SELMO NISSENBAUM",
  "LOUIS NORVAL",
  "TAKEO OBAYASHI",
  "DANIEL OCH",
  "MAJA OERI",
  "EYAL OFER",
  "Barbara Kruger",
  "Diane Arbus",
  "Daniel Arsham",
  "Ewa Juszkiewicz",
  "Beeple",
  "Stik",
  "Salman Toor",
  "Amoako Boafo",
  "Raqib Shaw",
  "Rashid Johnson",
  "JR",
  "Andrea Zittel",
  "Edoardo Tresoldi",
  "Chiharu Shiota",
  "Doug Aitken",
  "Shantell Martin",
  "Joan Cornellà",
  "Christoph Neimann",
  "Elena Soboleva",
  "BATIA AND IDAN OFER",
  "PETCH OSATHANUGRAH",
  "MICHAEL OVITZ",
  "ANDREA AND JOSÉ OLYMPIO PEREIRA",
  "MARSHA AND JEFFREY PERELMAN",
  "DARLENE AND JORGE M. PÉREZ",
  "AUGUSTO PERFETTI",
  "MATO PERIĆ",
  "LISA AND RICHARD PERRY",
  "FRANÇOIS PINAULT",
  "JANELLE AND ALDEN PINNELL",
  "SABINE AND HASSO PLATTNER",
  "CECILIA AND ERNESTO POMA",
  "LAURENE POWELL JOBS",
  "MIUCCIA PRADA AND PATRIZIO BERTELLI",
  "PENNY PRITZKER AND BRYAN TRAUBERT",
  "SULTAN SOOUD AL QASSEMI",
  "QIAO ZHIBING",
  "CINDY AND HOWARD RACHOFSKY",
  "PAUL RAETHER",
  "EMILY AND MITCHELL RALES",
  "PATRIZIA SANDRETTO RE REBAUDENGO",
  "BOB RENNIE",
  "LISA REUBEN",
  "CÉSAR AND MIMA REYES",
  "LOUISE AND LEONARD RIGGIO",
  "DANNY RIMER",
  "ELLEN AND MICHAEL RINGIER",
  "LINNEA CONRAD ROBERTS AND GEORGE ROBERTS",
  "KATIE AND AMNON RODAN",
  "RUBELL FAMILY",
  "JEN RUBIO AND STEWART BUTTERFIELD",
  "BETTY AND ISAAC RUDMAN",
  "ELHAM AND TONY SALAMÉ",
  "NADIA AND RAJEEB SAMDANI",
  "GORDON SCHACHAT",
  "SHERI AND HOWARD SCHULTZ",
  "HELEN AND CHARLES SCHWAB",
  "JAIME AND ANDREW SCHWARTZBERG",
  "KOMAL SHAH AND GAURAV GARG",
  "AMY AND LEO SHIH",
  "KIM AND JON SHIRLEY",
  "SARA AND JOHN SHLESINGER",
  "PETER SIMON",
  "ROBERT SOROS",
  "EMILE STIPP",
  "JULIA STOSCHEK",
  "SUH KYUNG-BAE",
  "BRETT AND DANIEL S. SUNDHEIM",
  "HIROSHI TAGUCHI AND MIWA TAGUCHI-SUGIYAMA",
  "LISA AND STEVE TANANBAUM",
  "ALEXANDER TEDJA",
  "SHEIKHA AL MAYASSA BINT HAMAD BIN KHALIFA AL THANI",
  "CARL AND MARILYNN THOMA",
  "DAVID THOMSON",
  "FRANCESCA THYSSEN-BORNEMISZA",
  "STEVE TISCH",
  "ANNE AND WOLFGANG TITZE",
  "ROBBI AND BRUCE E. TOLL",
  "MAGGIE AND RICHARD TSAI",
  "ROBERT TSAO",
  "JOSEF VASCOVITZ AND LISA GOODMAN",
  "PATRICIA PEARSON-VERGEZ AND JUAN VERGEZ",
  "ALICE WALTON",
  "JENNY JINYUAN WANG AND GUO GUANGCHANG",
  "WANG JIANLIN",
  "WANG WEI AND LIU YIQIAN",
  "MEI AND ALLAN WARBURG",
  "WEE EE CHEONG",
  "JUTTA AND SIEGFRIED WEISHAUPT",
  "ALAIN WERTHEIMER",
  "RIC WHITNEY AND TINA PERRY-WHITNEY",
  "REINHOLD WÜRTH",
  "ELAINE WYNN",
  "STEPHEN A. WYNN",
  "TADASHI YANAI",
  "YANG BIN",
  "JENNY YEH",
  "ANITA AND POJU ZABLUDOWICZ",
  "DASHA ZHUKOVA",
  "Thierry Guetta",
  "Andreas Wannerstedt",
  "Bosslogic",
  "Felipe Pantone",
  "DFace",
  "PichiAvo",
  "OSSO Magazine",
  "Anonymous",
  "Josie Lewis",
  "Carolyn Gavin",
  "Polly Staple",
  "Liese Chavez",
  "Alexis Christodoulou",
  "Rachel Altschuler",
  "Jean Julien",
  "Manzel Bowman",
  "Osgemeos",
  "Kenny Scharf",
  "Jenna Gribbon",
  "Erika Stearly",
  "Mark Tennant",
  "Faye Wei Wei",
  "Alexandra Levasseur",
  "Robert Ferri",
  "Ida Floreak",
  "Nickie Zimov",
  "Hugo Grenville",
  "Oprah Gail Winfrey",
  "Phil McGraw",
  "Tom Cruise",
  "Leonardo DiCaprio",
  "Jerry Seinfeld",
  "Steven Spielberg",
  "Tkaracan",
  "Haiiileen",
  "Tomerperetzart",
  "Yiannis Savva",
  "Megan Elizabeth",
  "Thomas Evans",
  "David Santiago",
  "Shaylen Amanda Broughton",
  "Matt Talbert",
  "Robert Szot",
  "brooklynlamb",
  "Ten Hundred",
  "Valerie McKeehan",
  "Minas Halaj",
  "Tony Castillo",
  "Christian MacNevin",
  "Chad Schoonmaker",
  "Ricky Watts",
  "Cinnamon Cooney",
  "Jennifer McChristian",
  "Tiago Azevedo",
  "Alexa Meade",
  "Caroline Geys",
  "ThankYouX",
  "Anoushka Mirchandani",
  "Andreea I. Berindei",
  "Anna Tsvell",
  "Chelsea Dawn Leopold",
  "AM DeBrincat",
  "Beverly Salas",
  "Neimy Kanani",
  "Kelsey Beckett",
  "Caitlin Hackett",
  "Sallyann",
  "Jane",
  "Rob Browning",
  "Feliks Kaparchuk",
  "Erika Lee Sears",
  "Matthew Sorgie",
  "Geaimages",
  "Kaiami",
  "Willie Hsu",
  "noel dobarganes",
  "NICK THOMM",
  "Nick Davis",
  "Monté L Miller",
  "Dalia Kantor",
  "Crystal Cosmos",
  "Christoph Niemann",
  "Arrogant Kei",
  "Gastt",
  "Nicolas Party",
  "Nusi Quero",
  "Oh de Laval",
  "Tolu Ayoka",
  "Art by Darcy",
  "Chloe Amy Avery",
  "Clara Cris",
  "Jenavieve Louie",
  "Joshua David McKenney",
  "Josie Lewis Art",
  "Kelly Emmrich",
  "Laika Studios",
  "Matt Chessco",
  "Saf Aljabouri",
  "Jennifer Higgie",
  "Olek",
  "Eva Respini",
  "Nancy Spector",
  "Wangechi Mutu",
  "Paul Octavious",
  "Os Gemeos",
  "Alexie Glass-Kantor",
  "Andrea Rosen",
  "Laurie Simmons",
  "Hank Willis Thomas",
  "Sanford Biggers",
  "Gaia",
  "Kimberly Drew",
  "Chaitanya Limaye",
  "Rob",
  "Kesh",
  "Vijaya Aswani",
  "MAMTA SINGH",
  "Benny Or",
  "Wylie West Creative",
  "Velvet Spectrum",
  "Richard Mehl",
  "Katie Jacobson",
  "Kati Prusenko",
  "Irina Vegele",
  "Mia Sima",
  "Jean Jullien",
  "Armanee Sinaga",
  "Perry Picasshoe",
  "Rudy Willingham",
  "Devon Rodriguez",
  "Alpay Efe",
  "Ben Johnson",
  "Taylor Quitara",
  "Sara Shakeel",
  "David Garibaldi",
  "Black Light Visuals",
  "Lydia Fenwick",
  "ONNI_Crafts",
  "ravncotino",
  "Colette Bernard",
  "barbiethewelder",
  "HTBSculptures",
  "Caroline Brodberick",
  "lucasdookus",
  "Michael_Zajkov",
  "Yusaku Maezawa",
  "Sarah Andelman",
  "Andrew Kuo",
  "Jasmin Hernandez",
  "Gary Yeh",
  "Josh Pierce",
  "Chad Knight",
  "Peter Tarka",
  "Tristan Eaton",
  "Ryan Hewett",
  "Marius Sperlich",
  "Mauro Ger",
  "Alessio Albi",
  "Isabel Giannuzzi",
  "Riccardo Accattatis",
  "Edoardo Zaggia",
  "Dan LuVisi",
  "Steven Harrington",
  "Christophe Young",
  "Calob Castellon",
  "Flore Maquin",
  "Eva Balloon",
  "Mike Meyers",
  "June Y. Cheng",
  "Gretel Lusky",
  "Madiel Lopez",
  "John Aslarona",
  "Niko Christian",
  "Julio Cesar",
  "Joshua Esmeralda",
  "Ivan Wong",
  "Valentina Remenar",
  "Maromi Sagi",
  "Dave Rapoza",
  "Eileen Gu",
  "Emma Raducanu",
  "Iga Świątek",
  "Venus Williams",
  "Coco Gauff",
  "Simone Biles",
  "Jessica Pegula",
  "Minjee Lee",
  "Candace Parker",
  "P.V. Sindhu",
  "Leylah Fernandez",
  "Lydia Ko",
  "Ons Jabeur",
  "Paula Badosa",
  "Lexi Thompson",
  "Jin Young Ko",
  "In Gee Chun",
  "Alex Morgan",
  "Megan Rapinoe",
  "Brooke Henderson",
  "Nelly Korda",
  "Caroline Garcia",
  "Garbiñe Muguruza",
  "Mickey Wright",
  "Martina Navratilova",
  "Abby Wambach",
  "Diana Taurasi",
  "Lindsey Vonn",
  "Carli Lloyd",
  "Allyson Felix",
  "Katie Ledecky",
  "Kimberly García León",
  "Shelly-Ann Fraser-Pryce",
  "Yulimar Rojas",
  "Sydney McLaughlin",
  "Tobi Amusan",
  "Alisha Lehmann",
  "P. V. Sindhi",
  "Crissa Jackson",
  "Maria Sharapova",
  "Ronda Rousey",
  "Daniel Kwan",
  "Daniel Scheinert",
  "Michelle Yeoh",
  "Brendon Fraser",
  "JAIME LEE CURTIS",
  "Will Smith",
  "Brad Pitt",
  "Tom Hardy",
  "Joaquin Phoenix",
  "Sofia Vergara",
  "Reese Witherspoon",
  "Angelina Jolie",
  "Julia Roberts",
];

const MARKETING_TEAM_JUST_RANDOM_TEXT = [
  "BRILLIANT!",
  "So touching, incredible",
  "I love him being a perfectionist",
  "Yoko had never disappointed me",
  "This goes beyond my words",
  "Olafur is one of the greatest",
  "What a remarkable lady!",
  "Legend, still like his work",
  "Muito bom!",
  "Beautiful and inspiring",
  "I love him he is so inspiring",
  "Another great artist!!",
  "Love, respect and adore",
  "En una palabra: TALENTO",
  "u are a speckle blessing to me",
  "I love her work so much!",
  "Amazing art!!🎉",
  "All time favorite artist!",
  "so inspiring and moving!",
  "Love Banksy’s work.",
  "Realmente bueno.",
  "Ariana Grande is a GODDESS",
  "Best Performer EVER",
  "i'm the problem, it's me",
  "selena gomez is a work of art",
  "Amo Justin Bieber muito mesmo",
  "my fave muse",
  "The Queen of Pop is here",
  "absolutely stunning",
  "billie eilish is breathtaking.",
  "cardi b is so flawless",
  "He always made masterpiece",
  "Apaixonada nessa música",
  "rap feminino Queen",
  "넌.. 신이야",
  "Being the best male vocalist",
  "pq ele tem q ser tão perfeito",
  "amo minha",
  "Adele sempre com voz forte",
  "PERFEITO",
  "lizzo is a happy vibe",
  "MADNESS! Love Coldplay",
  "Best artist",
  "Thanks for cheering me up!",
  "Best music of the year",
  "Post Malone é demais",
  "I love her voice",
  "camila is the cutest ever",
  "He is unbelievable",
  "She is totally unique",
  "Voz perfeita.",
  "His songs never gets old",
  "보라해 💜",
  "EXO 사랑하자",
  "BLACKPINK IN YOUR AREA",
  "NCT 축복은 정말 끝이 없구나",
  "언제나 곁에 있어",
  "twice es mi razón de vivir",
  "Got7 for ever",
  "visuals never disappoints😍",
  "세계 어디다 내놔도 너무너무 자랑스런 아이유",
  "Uma das melhores ❤",
  "Future is just confidence.",
  "His music is amazing🎃",
  "His works are so deep",
  "Pusha is a genius.",
  "Her arts are out of this world",
  "Zara Larsson é perfeita",
  "You're the light",
  "I love them together!!!",
  "Amazing voice. ",
  "That voice ❤️‍🩹",
  "Essas é a melhor música",
  "Sia's songs are timeless",
  "one of the greatest singers",
  "Legendary",
  "their vocals are insane",
  "I love this group so much",
  "Uma belíssima obra prima.",
  "True artist with classy art",
  "갓청하 예쁘고 노래 잘해",
  "DEBEMOS APOYARLAS!!!",
  "best girl Group for me",
  "Amazing voice. Love Sigrid.",
  "매튜 제발 한국 다시 와줘…",
  "Such a lovely song beautiful",
  "Simplesmente incrível❤",
  "Anitta, sempre será a número 1",
  "Que belleza ❤",
  "Truly a generational talent.",
  "I LOVE her phrasing!!!!",
  "💕 Love her voice & wordplay.",
  "Muy linda canción ❤",
  "노래 넘넘 좋은데 내한 와줄 수 있어......? 🥹",
  "Your flow IS international!",
  "Molly---world class!!!!!!!!!",
  "You guys are brilliant.",
  "This man is insane..",
  "You are just such a talent!!",
  "nostálgico",
  "시작됐다!! 나의 성공 시대",
  "반짝반짝 빛날 너를 환영해!"
];

export const SALES_TEAM_RANDOM_TEXT = () => {
  const brandName =
    SALES_TEAM_BRAND_NAME[
      Math.floor(Math.random() * SALES_TEAM_BRAND_NAME.length)
    ];
  // const emoji = EMOJI[Math.floor(Math.random() * EMOJI.length)];
  const randomTextArr = [
    `We love you, ${brandName}`,
    `wow it's ${brandName} `,
    `my anti-drug is ${brandName}`,
    `Lovely to see you here, ${brandName}`,
  ];
  return randomTextArr[Math.floor(Math.random() * randomTextArr.length)];
};

export const STRATEGY_TEAM_RANDOM_TEXT = () => {
  const num = Math.floor(Math.random() * 2);
  const investorEng =
    STRATEGY_TEAM_INVESTOR_ENG[
      Math.floor(Math.random() * STRATEGY_TEAM_INVESTOR_ENG.length)
    ];
  const investorKor =
    STRATEGY_TEAM_INVESTOR_KOR[
      Math.floor(Math.random() * STRATEGY_TEAM_INVESTOR_KOR.length)
    ];

  const randomTextEng =
  STRATEGY_TEAM_RANDOM_TEXT_ENG[
      Math.floor(Math.random() * STRATEGY_TEAM_RANDOM_TEXT_ENG.length)
    ];
  const randomTextKor =
  STRATEGY_TEAM_RANDOM_TEXT_KOR[
      Math.floor(Math.random() * STRATEGY_TEAM_RANDOM_TEXT_KOR.length)
    ];

  // const emoji = EMOJI[Math.floor(Math.random() * EMOJI.length)];
  const RANDOM_TEXT_KOR_ENG = `${investorKor} ${randomTextEng}`;
  const RANDOM_TEXT_ENG_KOR = `${investorEng} ${randomTextKor}`;
  return num === 1 ? RANDOM_TEXT_KOR_ENG : RANDOM_TEXT_ENG_KOR;
};

export const MARKETING_TEAM_RANDOM_TEXT = () => {
  const num = Math.floor(Math.random() * 3);
  const celebrityName =
    MARKETING_TEAM_CELEBRITY_NAME[
      Math.floor(Math.random() * MARKETING_TEAM_CELEBRITY_NAME.length)
    ];

  const celebrityText =
    MARKETING_TEAM_JUST_RANDOM_TEXT[
      Math.floor(Math.random() * MARKETING_TEAM_JUST_RANDOM_TEXT.length)
    ];

  // const emoji = Math.floor(Math.random() * EMOJI.length);

  const RANDOM_NAME = `${celebrityName}`;
  const RANDOM_TEXT = `${celebrityText}`;
  const RANDOM_NAME_TEXT = `${celebrityName} ${celebrityText}`;
  const text = {
    0: RANDOM_NAME,
    1: RANDOM_TEXT,
    2: RANDOM_NAME_TEXT,
  };
  return text[num];
};
