const getPainting = () => {
  let currentNum = localStorage.getItem("paintingNum");
  if (!currentNum) {
    currentNum = 1;
    localStorage.setItem("paintingNum", currentNum);
  }
  if (currentNum) {
    if (currentNum % 2 === 0) {
      currentNum = parseInt(currentNum) + 1;
    }
    currentNum = parseInt(currentNum) + 2;
    currentNum = 20 <= currentNum ? 1 : currentNum;
  }
  localStorage.setItem("paintingNum", currentNum.toString());
  return [`/paintings/p${currentNum}.jpg`, `/paintings/p${currentNum + 1}.jpg`];
};

export default getPainting;
