import { PerspectiveCamera } from "@react-three/drei";
import { EffectComposer, SMAA } from "@react-three/postprocessing";
import Stretch from "../components/Stretch";
import { useRef, Suspense, useState } from "react";
import { Matrix4 } from "three";
import MessageList from "../components/MessageList";
import TimeSquareFinal from "../components/TimesSquareFinal";
import Sun from "../components/Sun";
import { useThree } from "@react-three/fiber";
import CurvedPlane from "../utils/CurvedPlane";
import Rectangle from "../utils/CustomRectangle";
export default function Experience() {
  const { camera } = useThree();

  camera.aspect = 5900 / 3480;
  camera.updateProjectionMatrix();

  const [curveModelMatrix, setCurveModelMatrix] = useState(
    new Matrix4().fromArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  );
  const [leftSideModelMatrix, setLeftSideModelMatrix] = useState(
    new Matrix4().fromArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  );
  const [rightSideModelMatrix, setRightSideModelMatrix] = useState(
    new Matrix4().fromArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  );

  const stretchRef = useRef();

  const [isNightTime, setIsNightTime] = useState(false);


  return (
    <>
      <Suspense fallback={null}>
        <EffectComposer multisampling={8}>
          <Stretch
            active={true}
            ref={stretchRef}
            leftSideModelMatrix={new Float32Array(leftSideModelMatrix.elements)}
            curveModelMatrix={new Float32Array(curveModelMatrix.elements)}
            rightSideModelMatrix={
              new Float32Array(rightSideModelMatrix.elements)
            }
            cProjectionMatrix={
              new Float32Array(camera.projectionMatrix.elements)
            }
            cViewMatrix={new Float32Array(camera.matrixWorldInverse.elements)}
          />
          <SMAA />
        </EffectComposer>

        {/* Do not delete this mesh */}
        <mesh>
          <mesh
            position={[-(3.06 + 0.6494) / 2, 3.48 / 2, -0.6494 / 2]}
            rotation={[0, -Math.PI / 2, 0]}
          >
            <Rectangle
              width={1.82}
              height={3.48}
              color="red"
              getModelMatrix={setLeftSideModelMatrix}
            />
          </mesh>
          <mesh
            position={[-1.2053, 3.48 / 2.0, 0.5853]}
            rotation={[0, Math.PI, 0]}
          >
            <CurvedPlane getModelMatrix={setCurveModelMatrix} />
          </mesh>
          <mesh position={[0.6494 / 2, 3.48 / 2, 1.2347]}>
            <Rectangle
              width={3.06}
              height={3.48}
              color="blue"
              getModelMatrix={setRightSideModelMatrix}
            />
          </mesh>
        </mesh>

        <PerspectiveCamera
          makeDefault
          far={100}
          near={0.01}
          fov={26.99}
          position={[-9.91, -1.81, 6.01]}
          rotation={[0.58, -0.94, 0.47]}
          scale={0.76}
        />

        <Sun setIsNightTime={setIsNightTime} />

        <TimeSquareFinal
          {...{ isNightTime }}
        />
        <MessageList />
      </Suspense>
    </>
  );
}
