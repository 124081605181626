import {
  MARKETING_TEAM_RANDOM_TEXT,
  SALES_TEAM_RANDOM_TEXT,
  STRATEGY_TEAM_RANDOM_TEXT,
} from "../constants/variables";

const getRandomText = (stage, repeatingTime, setStage, setRepeatingTime) => {
  let str = '';
  if (stage === 0) {
    str = SALES_TEAM_RANDOM_TEXT();
    setStage(stage + 1);
  } else if (stage === 1) {
    if (repeatingTime < 2) {
      setRepeatingTime(repeatingTime + 1);
      str = STRATEGY_TEAM_RANDOM_TEXT();
    } else {
      setRepeatingTime(0);
      setStage(stage + 1);
    }
  } else {
    if (repeatingTime < 2) {
      setRepeatingTime(repeatingTime + 1);
      str = MARKETING_TEAM_RANDOM_TEXT();
    } else {
      setRepeatingTime(0);
      setStage(0);
    }
  }
  return str;
};

export default getRandomText;
