import { useCallback, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Svg, Text } from '@react-three/drei';

import twemoji from 'twemoji';
import { getCharacterWidth } from '../utils/text-utils';

import {
    NotoSansKorean,
    NotoSansJP,
    NotoSansSC,
    NotoSansTC,
    NotoSansArabic,
    NotoSansDevanagari,
    NotoSansEthiopic,
    NotoSansHebrew,
    NotoSansTamil,
    NotoSans,
    TH_Sarabun,
    Bengali_HindSiliguri,
} from '../fonts';

export default function Message(props) {
    const {
        color,
        language,
        removeTimeout,
        messageId,
        modifiedScale,
        text,
        x,
        y,
    } = props;

    const messageRef = useRef(null);
    const emojiRefs = useRef([]);

    const fonts = {
        english: NotoSansKorean,
        portuguese: NotoSansKorean,
        french: NotoSansKorean,
        german: NotoSansKorean,
        chineseSimplified: NotoSansSC,
        chineseTraditional: NotoSansTC,
        japanese: NotoSansJP,
        korean: NotoSansKorean,
        hindi: NotoSansDevanagari,
        hebrew: NotoSansHebrew,
        spanish: NotoSansKorean,
        italian: NotoSansKorean,
        dutch: NotoSansKorean,
        russian: NotoSans,
        finnish: NotoSansKorean,
        norwegian: NotoSansKorean,
        indonesian: NotoSansKorean,
        thai: TH_Sarabun,
        greek: NotoSansKorean,
        turkish: NotoSansKorean,
        arabic: NotoSansArabic,
        swedish: NotoSansKorean,
        danish: NotoSansKorean,
        malay: NotoSansKorean,
        filipino: NotoSansKorean,
        slovak: NotoSansKorean,
        romanian: NotoSansKorean,
        ukrainian: NotoSans,
        hungarian: NotoSansKorean,
        croatian: NotoSansKorean,
        kazakh: NotoSans,
        vietnamese: NotoSansKorean,
        amharic: NotoSansEthiopic,
        oromo: NotoSansEthiopic,
        tigrinya: NotoSansEthiopic,
        afar: NotoSansEthiopic,
        somali: NotoSansEthiopic,
        tamil: NotoSansTamil,
        ethiopic: NotoSansEthiopic,
        bengali: Bengali_HindSiliguri,
        other: NotoSansKorean,
    };

    const fadeDuration = 1.0;
    const timeOutTime = removeTimeout - fadeDuration * 1000;

    const popIn = () => {
        // fade in
        emojiRefs.current?.forEach(emoji => {
            const meshes = emoji?.children[0]?.children;
            meshes?.forEach(({ material }) => {
                gsap.to(material, {
                    opacity: 1,
                    ease: 'power1.out',
                    duration: 0.64,
                    overwrite: false,
                });
            });
        });

        for (const group of messageRef.current.children) {
            const children = group.children[0];

            // fade in
            if (children.type === 'Mesh') {
                gsap.to(children?.material, {
                    opacity: 1, // set opacity to 1
                    ease: 'power1.out',
                    duration: 0.64,
                    overwrite: false, // prevent the animation from being overwritten
                });
            }

            // Pop in
            gsap.to(group.scale, {
                x: 1.75,
                y: 1.75,
                z: 1.75,
                ease: 'power2.out', // use an exponential easing function
                duration: 0.3,
                overwrite: false, // prevent the animation from being overwritten
            });
            gsap.to(group.scale, {
                x: 1,
                y: 1,
                z: 1,
                ease: 'power2.out', // use an exponential easing function
                duration: 0.4,
                overwrite: false, // prevent the animation from being overwritten
            });
        }
    };

    const fadeOut = useCallback(() => {
        setTimeout(() => {
            emojiRefs.current?.forEach(emoji => {
                const meshes = emoji?.children[0]?.children;
                meshes?.forEach(({ material }) => {
                    gsap.to(material, {
                        opacity: 0,
                        ease: 'power1.out',
                        duration: fadeDuration,
                        overwrite: false,
                    });
                });
            });
        }, timeOutTime);

        for (const group of messageRef.current.children) {
            const children = group.children[0];
            setTimeout(() => {
                if (children.type === 'Mesh') {
                    gsap.to(children?.material, {
                        opacity: 0,
                        ease: 'power1.out',
                        duration: fadeDuration,
                        overwrite: false, // prevent the animation from being overwritten
                    });
                }
            }, timeOutTime);
        }
    }, [timeOutTime]);

    const runAnimation = useCallback(() => {
        popIn();
        fadeOut();
    }, [fadeOut]);

    const createTextComponents = text => {
        const div = document.createElement('div');
        div.innerHTML = text;
        twemoji.parse(div, (icon, options) => {
            return icon + '.svg';
        });

        const textComponents = [];
        let xOffset = 0;
        let index = 0;
        for (let i = 0; i < div.childNodes.length; i++) {
            const child = div.childNodes[i];
            if (child.nodeName === 'IMG') {
                const file = child.getAttribute('src');
                const emojiUrl = `./emojies/${file}`;

                textComponents.push(
                    <group
                        key={`Emoji_${messageId}_${i}`}
                        position-x={xOffset}
                        position-y={0.6}>
                        <Svg
                            ref={emoji => (emojiRefs.current[index++] = emoji)}
                            scale={0.03}
                            src={emojiUrl}
                        />
                    </group>
                );
                xOffset += 1.2; // increase the xOffset for the next emoji
            } else if (child.nodeType === Node.TEXT_NODE) {
                // if it's not an emoji image, just add the text
                const text = child.textContent;

                textComponents.push(
                    <group key={`Text_${messageId}_${i}`} position-x={xOffset}>
                        <Text
                            castShadow
                            receiveShadow
                            font={
                                language in fonts
                                    ? fonts[language]
                                    : fonts['other']
                            }
                            characters="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+-=[]{};:,./<>?"
                            anchorX="left">
                            {text}
                            <meshStandardMaterial
                                color={color}
                                transparent
                                opacity={0}
                            />
                        </Text>
                    </group>
                );

                for (const char of text)
                    xOffset += getCharacterWidth(char, language) * 5;
            }
        }
        return textComponents;
    };

    useLayoutEffect(() => {
        // set the initial opacity to 0
        emojiRefs.current?.forEach(emoji => {
            emoji?.children[0]?.children?.forEach(emojiMesh => {
                emojiMesh?.material?.setValues({
                    transparent: true,
                    opacity: 0,
                });
            });
        });

        runAnimation();
    }, []);

    return (
        <group
            ref={messageRef}
            position={[x, y, -1.15]}
            scale={0.2 * modifiedScale}
            {...props}
            dispose={null}>
            {createTextComponents(text)}
        </group>
    );
}
