import { Effect } from 'postprocessing';
import { Uniform } from 'three';

const fragmentShader = `
  precision highp float;
  uniform mat4 leftSideModelMatrix;
  uniform mat4 curveModelMatrix;
  uniform mat4 rightSideModelMatrix;
  uniform mat4 cProjectionMatrix;
  uniform mat4 cViewMatrix;
  uniform vec2 cylinderSrcPoints[4];
  uniform mat3 leftTransformMatrix;
  uniform mat3 cylinderTransformMatrix;
  uniform mat3 rightTransformMatrix;
  uniform sampler2D tDiffuse;

  void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    float screenHeight = 3.480;
    float screenWidth = 5.9;
    
    vec2 transformedUv = uv;
    if (uv.x < 0.3084745) {
      float leftRatio = 1.820 / screenWidth;
      float localX = uv.x / leftRatio;
      float width = 1.820;

      float height = 3.480;
      
      float y = -0.5 * height + uv.y * height;
      float x = -0.5 * width + localX * width;
      float z = 0.0;
      
      vec3 cPosition = vec3(x, y, z);
      vec4 pPosition = cProjectionMatrix * cViewMatrix * leftSideModelMatrix * vec4(cPosition, 1.0);
      vec3 pPosition3 = pPosition.xyz / pPosition.w;
      vec2 pUV = pPosition3.xy;
      
      transformedUv = vec2((pUV.x + 1.0) / 2.0, (pUV.y + 1.0) / 2.0);
    } 
    else if (uv.x >= 0.4813) {
      float rightWidth = 3.060 / screenWidth;
      float rightStart = 0.4813;
      float localX = (uv.x - rightStart) / rightWidth;
      float width = 3.060;
      float height = 3.480;
      
      float y = -0.5 * height + uv.y * height;
      float x = -0.5 * width + localX * width;
      float z = 0.0;
      
      vec3 cPosition = vec3(x, y, z);
      vec4 pPosition = cProjectionMatrix * cViewMatrix * rightSideModelMatrix * vec4(cPosition, 1.0);
      vec3 pPosition3 = pPosition.xyz / pPosition.w;
      // vec2 pUV = pPosition3.xy / pPosition3.z;
      vec2 pUV = pPosition3.xy; 

      
      transformedUv = vec2((pUV.x + 1.0) / 2.0, (pUV.y + 1.0) / 2.0);
    } 
    else {
      float screenHeight = 3.480;
      float screenWidth = 1.820 + 1.020 + 3.060;
      float leftRatio = 1.820 / screenWidth;
      float cylinderWidth = 1.020 / screenWidth;
      float localX = (uv.x - leftRatio) / cylinderWidth;

      float radius = 0.6493521678;
      float height = 3.480;
      
      float y = -0.5 * height + uv.y * height;
      
      float cu = localX;
      float angle = (cu * PI) / 2.0;
      
      float x = radius * cos(angle);
      float z = -radius * sin(angle);
      
      vec3 cPosition = vec3(x, y, z);
      vec4 pPosition = cProjectionMatrix * cViewMatrix * curveModelMatrix * vec4(cPosition, 1.0);
      vec3 pPosition3 = pPosition.xyz / pPosition.w;
      // vec2 pUV = pPosition3.xy / pPosition3.z;
      vec2 pUV = pPosition3.xy; 

      transformedUv = vec2((pUV.x + 1.0) / 2.0, (pUV.y + 1.0) / 2.0);   
    }

    outputColor = texture2D(tDiffuse, transformedUv);
  }
`;

export default class StretchEffect extends Effect {
    constructor(props) {
        super('StretchEffect', fragmentShader, {
            uniforms: new Map([
                ['leftSideModelMatrix', new Uniform(props.leftSideModelMatrix)],
                ['curveModelMatrix', new Uniform(props.curveModelMatrix)],
                [
                    'rightSideModelMatrix',
                    new Uniform(props.rightSideModelMatrix),
                ],
                ['cProjectionMatrix', new Uniform(props.cProjectionMatrix)],
                ['cViewMatrix', new Uniform(props.cViewMatrix)],
            ]),
        });
    }
}
