import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  useGLTF,
  useAnimations,
  MeshReflectorMaterial,
  useTexture,
} from "@react-three/drei";
import {
  DoubleSide,
  LinearMipMapLinearFilter,
  LoopOnce,
  NearestFilter,
} from "three";

import TimesSquareModel from "./TimesSquareFinal.glb";
import { StatusService } from "../api";
import getPainting from "../utils/painting-util";

export default function TimeSquareFinal(props) {
  const { isNightTime } = props;

  const group = useRef();
  const { nodes, materials, animations } = useGLTF(TimesSquareModel);
  const { actions } = useAnimations(animations, group);
  const [paintings, setPaintings] = useState([]);

  const bottomPaintingRef = useRef();
  const bottomFrameRef = useRef();
  const bottomPaintingImageRef = useRef();
  const topPaintingRef = useRef();
  const topFrameRef = useRef();
  const topPaintingImageRef = useRef();

  const [topPainting, bottomPainting] = useTexture(paintings);

  const handleAnimationIsDone = useCallback(() => {
    localStorage.setItem('isDoneAnimated', true);
  }, []);

  useEffect(()=>{
    setPaintings(getPainting());
  },[]);

  useEffect(() => {
    console.log("useEffect called");
    console.log("Github commit message: feat: remove useContext");
    // console.log(franc("你好世界", { minLength: 3 }));
    if (topPainting) {
      bottomPainting.magFilter = NearestFilter;
      bottomPainting.minFilter = LinearMipMapLinearFilter;
      topPainting.magFilter = NearestFilter;
      topPainting.minFilter = LinearMipMapLinearFilter;

      // flip texture horizontally
      bottomPaintingImageRef.current.scale.z = -1;
      topPaintingImageRef.current.scale.z = -1;

      // scale paintings to their correct aspect ratio
      const bottomPaintingAspectRatio =
        bottomPainting.image.width / bottomPainting.image.height;

      if (bottomPaintingAspectRatio < 1) {
        bottomPaintingRef.current.scale.set(1, 1, bottomPaintingAspectRatio);
        bottomFrameRef.current.scale.set(1, 1, bottomPaintingAspectRatio);
      } else if (bottomPaintingAspectRatio > 1) {
        bottomPaintingRef.current.scale.set(
          1,
          bottomPainting.image.height / bottomPainting.image.width,
          1
        );
        bottomFrameRef.current.scale.set(
          1,
          bottomPainting.image.height / bottomPainting.image.width,
          1
        );
      }

      const topPaintingAspectRatio =
        topPainting.image.width / topPainting.image.height;

      if (topPaintingAspectRatio < 1) {
        topPaintingRef.current.scale.set(1, 1, topPaintingAspectRatio);
        topFrameRef.current.scale.set(1, 1, topPaintingAspectRatio);
      } else if (topPaintingAspectRatio > 1) {
        topPaintingRef.current.scale.set(
          1,
          topPainting.image.height / topPainting.image.width,
          1
        );
        topFrameRef.current.scale.set(
          1,
          topPainting.image.height / topPainting.image.width,
          1
        );
      }
      const playAnimations = () => {
        StatusService.restartService();
        Object.keys(actions)
          .filter((actions) => !actions.includes("Camera"))
          .forEach((action) => {
            if (actions[action] && actions[action].setLoop) {
              actions[action].setLoop(LoopOnce).reset().play();
              actions[action]._mixer.addEventListener(
                "finished",
                handleAnimationIsDone
              );
            }
          });
      };
      Object.keys(actions)
        .filter((actions) => !actions.includes("Camera"))
        .forEach((action) => {
          actions[action].clampWhenFinished = true;
        });

      playAnimations();

      // function scheduleAnimation() {
      //   const now = new Date();
      //   const milliseconds = now.getMilliseconds();
      //   const seconds = now.getSeconds();
      //   if (seconds < 2) {
      //     setTimeout(playAnimations, 10);
      //   } else {
      //     const delay = (59 - seconds) * 1000 - milliseconds - 1700 - 2000;
      //     console.log(delay);
      //     setTimeout(playAnimations, delay);
      //   }
      // }
      // scheduleAnimation();

      //   const handleResize = () => {
      //     playAnimations();
      //   };

      //   const handleVisibilityChange = () => {
      //     if (!document.hidden) {
      //       playAnimations();
      //     }
      //   };

      //   window.addEventListener("resize", handleResize);
      //   document.addEventListener("visibilitychange", handleVisibilityChange);

      //   return () => {
      //     window.removeEventListener("resize", handleResize);
      //     document.removeEventListener(
      //       "visibilitychange",
      //       handleVisibilityChange
      //     );
      //   };
    } else {
      localStorage.removeItem('isDoneAnimated');
      localStorage.setItem('isDoneAnimated', false);
    }
  }, [topPainting]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="wall"
          castShadow
          receiveShadow
          geometry={nodes.wall.geometry}
          material={materials["Material.009"]}
          position={[0.74, 1.74, -0.74]}
        >
          <MeshReflectorMaterial
            mixBlur={1.0}
            mixStrength={0.4}
            resolution={1024}
            {...materials["Material.009"]}
          />
        </mesh>
        <mesh
          name="wall002"
          castShadow
          receiveShadow
          geometry={nodes.wall002.geometry}
          material={materials["Material.054"]}
          position={[0.02, 3.46, -0.02]}
        />
        <mesh
          name="wall010"
          castShadow
          receiveShadow
          geometry={nodes.wall010.geometry}
          material={materials["Material.054"]}
          position={[0.05, 3.47, -0.05]}
        />
        <mesh
          name="wall001"
          castShadow
          receiveShadow
          geometry={nodes.wall001.geometry}
          material={materials["Material.002"]}
          position={[-0.37, 1.77, 0.43]}
        />
        <mesh
          name="wall005"
          castShadow
          receiveShadow
          geometry={nodes.wall005.geometry}
          material={materials["Material.054"]}
          position={[0.05, 3.47, -0.07]}
        />
        <group name="Text046" position={[-1.85, 3.18, -0.47]}>
          <mesh
            name="Text002"
            castShadow
            receiveShadow
            geometry={nodes.Text002.geometry}
            material={materials["Material.057"]}
          />
          <mesh
            name="Text002_1"
            castShadow
            receiveShadow
            geometry={nodes.Text002_1.geometry}
            material={materials["Material.081"]}
          />
        </group>
        <mesh
          name="Cube005"
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials["Material.004"]}
          position={[1.83, 2.34, -0.78]}
        />
        <group name="shutter">
          <mesh
            name="Text"
            // castShadow
            receiveShadow
            geometry={nodes.Text.geometry}
            material={materials.Material}
            position={[2.41, 0.46, -0.03]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={0.69}
          />
          <group name="wall007" position={[-1.61, -0.78, 1]}>
            <mesh
              name="Cube018"
              // castShadow
              receiveShadow
              geometry={nodes.Cube018.geometry}
              material={materials["Material.042"]}
            />
            <mesh
              name="Cube018_1"
              // castShadow
              receiveShadow
              geometry={nodes.Cube018_1.geometry}
              material={materials["Material.042"]}
            />
          </group>
        </group>
        <mesh
          name="Cube004"
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials["Material.004"]}
          position={[1.83, 2.34, 0.78]}
        />
        <mesh
          name="Cube007"
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials["Material.004"]}
          position={[1.83, 1.07, -0.78]}
        />
        <mesh
          name="Cube006"
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials["Material.004"]}
          position={[1.83, 1.07, 0.78]}
        />
        <group
          name="Curve"
          position={[0.05, 3.09, 1.23]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[5.78, 3.11, 5.43]}
        >
          <mesh
            name="Curve_1"
            castShadow
            receiveShadow
            geometry={nodes.Curve_1.geometry}
            material={materials["Material.045"]}
          />
          <mesh
            name="Curve_2"
            castShadow
            receiveShadow
            geometry={nodes.Curve_2.geometry}
            material={materials["Material.046"]}
          />
          <mesh
            name="Curve_3"
            castShadow
            receiveShadow
            geometry={nodes.Curve_3.geometry}
            material={materials["Material.047"]}
          />
        </group>
        {/* fix the "tear" effect in the QR plane */}
        <group position={[-0.01, 0, 0]}>
          <mesh
            name="Plane002"
            castShadow
            receiveShadow
            geometry={nodes.Plane002.geometry}
            material={materials["Material.005"]}
            position={[-1.84, 3.05, 0.73]}
            rotation={[Math.PI / 2, 0, 1.46]}
            scale={[0.4, 0.37, 0.49]}
          />
        </group>
        <group name="topPaintingGroup">
          <mesh
            ref={topFrameRef}
            name="frame002"
            castShadow
            receiveShadow
            geometry={nodes.frame002.geometry}
            material={materials["Material.001"]}
            position={[1.85, 2.34, 0]}
            rotation={[Math.PI, 0, 0]}
          />
          <group
            ref={topPaintingRef}
            name="pic004"
            position={[1.85, 2.34, 0]}
            rotation={[Math.PI, 0, 0]}
          >
            <mesh
              name="큐브002"
              castShadow
              receiveShadow
              geometry={nodes.큐브002.geometry}
              material={materials.Picframe}
            />
            <mesh
              ref={topPaintingImageRef}
              name="큐브002_1"
              castShadow
              receiveShadow
              geometry={nodes.큐브002_1.geometry}
              // material={materials['Material.058']}
            >
              <meshStandardMaterial map={topPainting} side={DoubleSide} />
            </mesh>
          </group>
        </group>
        <group name="bottomPaintingGroup">
          <mesh
            ref={bottomFrameRef}
            name="frame001"
            castShadow
            receiveShadow
            geometry={nodes.frame001.geometry}
            material={materials["Material.001"]}
            position={[1.85, 1.1, 0]}
            rotation={[0, 0, 0]}
          />
          <group
            ref={bottomPaintingRef}
            name="pic001"
            position={[1.85, 1.1, 0]}
            rotation={[Math.PI, 0, 0]}
          >
            <mesh
              name="큐브004"
              castShadow
              receiveShadow
              geometry={nodes.큐브004.geometry}
              material={materials.Picframe}
            />
            <mesh
              ref={bottomPaintingImageRef}
              name="큐브004_1"
              castShadow
              receiveShadow
              geometry={nodes.큐브004_1.geometry}
              // material={materials['Material.038']}
            >
              <meshStandardMaterial map={bottomPainting} side={DoubleSide} />
            </mesh>
          </group>
        </group>
        <mesh
          name="wall003"
          castShadow
          receiveShadow
          geometry={nodes.wall003.geometry}
          material={materials["Material.044"]}
          position={[-1.61, -3.45, 1]}
        />
        <mesh
          name="wall004"
          castShadow
          receiveShadow
          geometry={nodes.wall004.geometry}
          material={materials["Material.020"]}
          position={[-1.61, 0, 1]}
        />
        <mesh
          name="Plane007"
          //   castShadow
          receiveShadow
          geometry={nodes.Plane007.geometry}
          material={materials["Material.008"]}
          position={[0.06, 4.6, -1.24]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        {!isNightTime && (
          <mesh
            name="Plane007"
            //   castShadow
            receiveShadow
            geometry={nodes.Plane007.geometry}
            material={materials["Material.008"]}
            position={[0.06, 4.6, -1.24]}
            rotation={[Math.PI / 2, 0, 0]}
          />
        )}
      </group>
    </group>
  );
}

useGLTF.preload(TimesSquareModel);
