import client from "../client";
import { useQuery } from "@tanstack/react-query";

const TextService = {
  useGetText: (fn, param, isDone) =>
    useQuery({
      queryKey: ["fetchText"],
      queryFn: async () => {
        return await client.get(`/texts?accessKey=${param}`);
      },
      onError: async (res) => {
        await client.post(`/errors`, {
          error: `TextService useGetText line 12 ${res}`,
        });
      },
      onSuccess: fn,
      refetchInterval: isDone ? 250 : -1,
      enabled: isDone,
    }),
};

export default TextService;
