import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';

const CurvedPlaneMaterial = shaderMaterial(
    {},
    // Vertex Shader
    `
  varying vec2 vUv;

  void main() {
    vUv = uv;

    float pi = 3.14159265359;
    float height = 3.480;
    float radius = 1.02 * 2.0 / pi;
    // float radius = 0.5;

    float cv = uv.y;
    float y = -0.5 * height + uv.y * height;

    float cu = uv.x;
    // float cu = 1.0 - uv.x;
    float angle = (cu * 3.14159265359) / 2.0;

    float x = radius * cos(angle);
    float z = -radius * sin(angle);

    vec3 transformedPosition = vec3(x, y, z);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);
  }
  `,
    // Fragment Shader
    `
  varying vec2 vUv;

  void main() {
    // gl_FragColor = vec4(vUv, 0.0, 1.0);
    gl_FragColor = vec4(0.0, 1.0, 0.0, 0.0);
  }
  `
);

extend({ CurvedPlaneMaterial });

export default CurvedPlaneMaterial;
