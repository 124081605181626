import { Vector3, MathUtils } from "three";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Sky } from "@react-three/drei";
import * as SunCalc from "suncalc";

export default function Sun(props) {
  const { setIsNightTime } = props;

  const [sunPos, setSunPos] = useState({ x: 0, y: 0, z: 0 });
  const [spotLightIntensity, setSpotLightIntensity] = useState(1.0);
  const [ambiLightIntensity, setAmbiLightIntensity] = useState(0.5);
  const [pointLightIntensity, setPointLightIntensity] = useState(0.5);

  const [location, setLocation] = useState("Times Square");
  const locations = useMemo(
    () => ({
      Hawaii: {
        country: "US",
        lat: "21.309865287040505",
        lng: "-157.85883747381394",
      },
      "San Francisco": {
        country: "US",
        lat: "37.77493",
        lng: "-122.41942",
      },
      "Times Square": {
        country: "US",
        lat: "40.71427",
        lng: "-74.00597",
      },
      London: {
        country: "GB",
        lat: "51.50853",
        lng: "-0.12574",
      },
      Stockholm: {
        country: "SE",
        lat: "59.334591",
        lng: "18.063240",
      },
      Athens: {
        country: "GR",
        lat: "37.983810",
        lng: "23.727539",
      },
      Bangladesh: {
        country: "BD",
        lat: "23.810331",
        lng: "90.412521",
      },
      "Ho Chi Minh City": {
        country: "VN",
        lat: "10.82302",
        lng: "106.62965",
      },
      Seoul: {
        country: "KR",
        lat: "37.566",
        lng: "126.9784",
      },
      Tokyo: {
        country: "JP",
        lat: "35.652832",
        lng: "139.839478",
      },
    }),
    []
  );

  const setLocationSunPosition = useCallback(
    (city) => {
      setLocation(city);
      const solarPosition = SunCalc.getPosition(
        new Date(),
        locations[city].lat,
        locations[city].lng
      );
      const sunPosition = new Vector3().setFromSphericalCoords(
        500,
        Math.PI / 2 - solarPosition.altitude,
        -solarPosition.azimuth + Math.PI
      );
      setSunPos({
        x: -sunPosition.x,
        y: sunPosition.y,
        z: -sunPosition.z,
      });

      // Calculate the lights' intensity based on sun position
      const maxSunHeight = 450;
      const clampedSunY = MathUtils.clamp(sunPosition.y, 0, maxSunHeight);

      const dLightIntensity = MathUtils.mapLinear(clampedSunY, 0, 450, 0, 1);
      setSpotLightIntensity(dLightIntensity);

      const aLightIntensity = MathUtils.mapLinear(
        clampedSunY,
        0,
        450,
        0.3,
        0.8
      );
      setAmbiLightIntensity(aLightIntensity);

      if (sunPosition.y < 20) {
        setPointLightIntensity(0.03);
        setIsNightTime(true);
      } else if (sunPosition.y < 50) {
        setPointLightIntensity(0.01);
        setIsNightTime(true);
      } else {
        setPointLightIntensity(0.0);
        setIsNightTime(false);
      }
    },
    [locations, setIsNightTime]
  );

  const spotLightRef = useRef();
  useEffect(() => {
    setLocationSunPosition(location);

    const interval = setInterval(() => {
      setLocationSunPosition(location);
    }, 1000 * 60); // Update sun position every minute

    // Set the spotlight target closer to the center of the Times Square
    // instead of pointing at the floor
    spotLightRef?.current?.target?.position?.set(0, 1.25, 0);

    return () => clearInterval(interval);
  }, [location, setLocationSunPosition]);

  return (
    <>
      <Sky distance={500} sunPosition={[sunPos.x, sunPos.y, sunPos.z]} />
      <ambientLight intensity={ambiLightIntensity} />

      <spotLight
        ref={spotLightRef}
        castShadow
        position={[sunPos.x * 0.1, sunPos.y * 0.1, sunPos.z * 0.1]}
        color="#ffffff"
        intensity={spotLightIntensity}
        distance={0}
        shadowMapWidth={8192}
        shadowMapHeight={8192}
        shadow-normalBias={0.1}
        decay={1}
        focus={1}
        angle={Math.PI / 35}
      />

      <pointLight
        position={[-1.55, 0, 1]}
        color="#ffffff"
        intensity={0.3}
        shadowMapWidth={4096}
        shadowMapHeight={4096}
        shadow-normalBias={0.05}
      />

      <group>
        <pointLight
          castShadow
          position={[1.71, 2.175, -0.78]}
          color="#f7c785"
          intensity={pointLightIntensity}
          shadowMapWidth={4096}
          shadowMapHeight={4096}
          shadow-normalBias={0.05}
        />
        <pointLight
          castShadow
          position={[1.71, 2.175, 0.78]}
          color="#f7c785"
          intensity={pointLightIntensity}
          shadowMapWidth={4096}
          shadowMapHeight={4096}
          shadow-normalBias={0.05}
        />
        <pointLight
          castShadow
          position={[1.71, 0.89, -0.78]}
          color="#f7c785"
          intensity={pointLightIntensity}
          shadowMapWidth={4096}
          shadowMapHeight={4096}
          shadow-normalBias={0.05}
        />
        <pointLight
          castShadow
          position={[1.71, 0.89, 0.78]}
          color="#f7c785"
          intensity={pointLightIntensity}
          shadowMapWidth={4096}
          shadowMapHeight={4096}
          shadow-normalBias={0.05}
        />
      </group>
    </>
  );
}
